import { Box, Typography } from '@mui/material'

export function PageUnavailable() {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ mt: { xs: 10, md: 17 }, mb: 5 }}>
          We are sorry!
        </Typography>
        <Typography sx={{ maxWidth: '520px' }}>
          There was an unexpected error. Please, reload the page or try again
          later.
        </Typography>
      </Box>
    </Box>
  )
}
