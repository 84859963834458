import { UseQueryResult } from '@tanstack/react-query'
import type {
  Context,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react'
import { createContext, useState } from 'react'
import { SWRResponse } from 'swr'
import { IError } from '~api/types'
import createContextStateHook from '~utils/create-hook-for-context-state'
import { FavoritesState, useFavorites } from './favorites-provider'
import { UserState, useUser } from './user-provider'

export type BaseStoreState = {
  Favorites: FavoritesState
  User: UserState
  isDataLoaded: (
    swrData: SWRResponse<any, IError> | UseQueryResult<any, Error>,
  ) => boolean
  setTagFilters: Dispatch<SetStateAction<string[]>>
  tagFilters: string[]
}

const StoreContext = createContext<BaseStoreState | null>(
  null,
) as Context<BaseStoreState>

export default function StoreProvider({ ...props }: PropsWithChildren) {
  const [tagFilters, setTagFilters] = useState<string[]>([])
  const Favorites = useFavorites()
  const User = useUser()

  function isDataLoaded(
    swrData: SWRResponse<any, IError> | UseQueryResult<any, Error>,
  ): boolean {
    return Boolean(!swrData.isLoading && (swrData.error || swrData.data))
  }

  return (
    <StoreContext.Provider
      value={{
        tagFilters,
        setTagFilters,
        isDataLoaded,
        User,
        Favorites,
      }}
      {...props}
    />
  )
}

export const useStore = createContextStateHook(StoreContext, 'StoreContext')
