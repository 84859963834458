import { theme } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

export interface MuiDrawerData {
  defaultProps?: ComponentsProps['MuiDrawer']
  styleOverrides?: ComponentsOverrides['MuiDrawer']
  variants?: ComponentsVariants['MuiDrawer']
}

export const MuiDrawer: MuiDrawerData = {
  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        backgroundColor: theme.COLORS.secondary.darkBlue.primary,
      },
    },
  },
}
