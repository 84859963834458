import { useAuth0 } from '@auth0/auth0-react'
import { Alert } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useMatches } from 'react-router-dom'
import { useGetMeID } from '~hooks/queries/me/use-get-me-id'

export function MissingPoolNotification() {
  const matches = useMatches()
  const { isAuthenticated } = useAuth0()
  const { data, isLoading } = useGetMeID()
  if (isLoading) {
    return <></>
  }

  // @ts-ignore
  const isRouteWithPools = matches.some((match) => match.handle?.withPools)

  if (isAuthenticated && isRouteWithPools && !data?.profitCenterPools.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert
          variant="error"
          text="Oops! There has been an error getting your pools. Please reload the page or try again later."
        />
      </Box>
    )
  }

  return <></>
}
