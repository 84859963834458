import { useAuth0 } from '@auth0/auth0-react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { UserSettings, UserSettingsInput } from '~api/gql/generated/graphql'
import { upsertUserMutation } from '~api/gql/mutations'
import { getUserQuery } from '~api/gql/queries'
import { useGraphQL } from '~hooks/use-graphql'
import { useGraphQLMutation } from '~hooks/use-graphql-mutation'

type UserSettingsContextType = {
  saveUserSettings: (newUserSettings: UserSettings) => Promise<void>
  userSettings: UserSettings | undefined
}

const UserSettingsContext = createContext<UserSettingsContextType | undefined>(
  undefined,
)

function UserSettingsProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, user } = useAuth0()
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    undefined,
  )

  const { mutateAsync: mutateUser, isPending: upsertUserIsLoading } =
    useGraphQLMutation(upsertUserMutation)
  const { data: userData, isFetched: userIsFetched } = useGraphQL(
    getUserQuery,
    { enabled: !!user?.email },
    { userID: user?.email || '' },
  )

  // If no user exists, create one with an empty userSettings object
  useEffect(() => {
    if (
      isAuthenticated &&
      user?.email &&
      !upsertUserIsLoading &&
      !userData?.getUser &&
      userIsFetched
    ) {
      mutateUser({ userID: user.email as string, userSettings: {} })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, mutateUser, user, userIsFetched])

  useEffect(() => {
    if (userData?.getUser?.userSettings && userIsFetched) {
      setUserSettings(userData.getUser.userSettings)
    }
  }, [userData?.getUser?.userSettings, userIsFetched])

  const saveUserSettings = useCallback(
    async (newUserSettings: UserSettingsInput) => {
      if (isAuthenticated && user?.email) {
        await mutateUser({
          userID: user.email as string,
          userSettings: newUserSettings,
        })
      }
      setUserSettings(newUserSettings)
    },
    [isAuthenticated, user?.email, mutateUser],
  )

  const value = useMemo(() => {
    return { userSettings, saveUserSettings }
  }, [userSettings, saveUserSettings])

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  )
}

function useUserSettings() {
  const context = useContext(UserSettingsContext)
  if (context === undefined) {
    throw new Error(
      'useUserSettings must be used within a UserSettingsProvider',
    )
  }
  return context
}

export { UserSettingsProvider, useUserSettings }
