import {
  ClickPoint,
  CloseIcon,
  ExportIcon,
  ImportIcon,
  LockIcon,
  TextButton,
  theme,
  useEventListener,
} from '@maersktankersdigital/web-components'
import { ClickAwayListener } from '@mui/base'
import * as React from 'react'
import { useStore } from 'src/context/store-provider'

import { IUserSettingsPositionListView } from '~api/user/read'
import { SaveIcon } from '~assets/svgs/save'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  CompanyListName,
  FooterActions,
  HeaderWrapper,
  IconWrapper,
  ListName,
  ListWrapper,
  MenuItem,
  MyListsMenuContentWrapper,
  NameWrapper,
  SecondaryTextButton,
  StyledCheckIcon,
  StyledClickPoint,
  StyledDeleteIcon,
  StyledFileInput,
  StyledFileInputLabel,
  StyledTextButton,
  TopWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/my-list-menu-content/my-list-menu-content.styles'

interface Props {
  handleShowDeleteListModal: (listName: string) => void
  handleShowSaveNewListModal: (isSharedList?: boolean) => void
  handleToggleMyLists: () => void
}

function MyListMenuContent({
  handleShowSaveNewListModal,
  handleToggleMyLists,
  handleShowDeleteListModal,
}: Props) {
  const {
    settings,
    saveView,
    persistActiveView,
    activeView,
    deleteView,
    saveSettings,
  } = usePositionListView()
  const { User } = useStore()
  useEventListener('keydown', (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      handleToggleMyLists()
    }
  })
  const {
    changesPending,
    changesPendingSharedLists,
    activeViewName,
    myLists,
    sharedLists,
  } = settings || {}
  const isEditor = useHasScope(ExactScopeName.positionListAdmin)
  const isSharedList = !!activeView?.createdAt
  const isDefaultList = activeViewName === 'default'
  const { setStatus } = useAnimatedAlert()
  const hasSharedLists = Object.keys(sharedLists || {}).length > 0
  const changesPendingAndNotDefaultOrShared =
    changesPending && !isDefaultList && !isSharedList
  const disableSaveButton = !changesPending || isSharedList
  const changesPendingSharedListsAndNotDefault =
    changesPendingSharedLists && !isDefaultList

  const handleShareList = (
    list?: IUserSettingsPositionListView,
    filename?: string,
  ) => {
    const json = JSON.stringify(list, null, 2)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename ? `${filename}.json` : 'list.json'
    link.click()
    URL.revokeObjectURL(url)
  }

  const handleImportList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (!file) return
    const reader = new FileReader()

    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (typeof event.target?.result === 'string') {
        const data = JSON.parse(event.target.result)
        saveView(data)
        saveSettings({ changesPending: true, activeViewName: data.name })

        setStatus('List succesfully imported', 5000)
      } else {
        setStatus('Error importing list', 5000, 'error')
      }
    }

    reader.readAsText(file)
  }

  return (
    <ClickAwayListener onClickAway={handleToggleMyLists}>
      <MyListsMenuContentWrapper>
        <TopWrapper>
          <HeaderWrapper>
            <StyledTextButton
              disabled={disableSaveButton}
              icon={<SaveIcon />}
              data-cy="save-list-button"
              onClick={() =>
                changesPendingAndNotDefaultOrShared
                  ? persistActiveView('myLists')
                  : handleShowSaveNewListModal()
              }
            >
              {changesPendingAndNotDefaultOrShared
                ? 'Save changes'
                : 'Save current view'}
            </StyledTextButton>

            <ClickPoint ariaLabel="close menu" onClick={handleToggleMyLists}>
              <CloseIcon />
            </ClickPoint>
          </HeaderWrapper>
          {changesPendingAndNotDefaultOrShared && (
            <SecondaryTextButton
              icon={<SaveIcon />}
              onClick={() => handleShowSaveNewListModal()}
            >
              Save as new list
            </SecondaryTextButton>
          )}
        </TopWrapper>

        <ListWrapper>
          {myLists &&
            Object.keys(myLists).map((listName) => {
              if (listName === 'default') return

              return (
                <MenuItem
                  onClick={() => saveView(undefined, listName)}
                  key={listName}
                  data-cy={listName}
                >
                  <NameWrapper>
                    {activeViewName === listName && (
                      <StyledCheckIcon size={18} />
                    )}
                    <ListName>{listName}</ListName>
                  </NameWrapper>
                  <StyledClickPoint
                    ariaLabel="delete list"
                    onClick={() => handleShowDeleteListModal(listName)}
                  >
                    <StyledDeleteIcon size={16} />
                  </StyledClickPoint>
                </MenuItem>
              )
            })}
          {sharedLists && hasSharedLists && !isEditor && (
            <CompanyListName>COMPANY LISTS</CompanyListName>
          )}
          {isEditor && (
            <TopWrapper>
              <StyledTextButton
                icon={<SaveIcon />}
                onClick={() =>
                  changesPendingSharedListsAndNotDefault
                    ? persistActiveView('sharedLists')
                    : handleShowSaveNewListModal(true)
                }
              >
                {changesPendingSharedListsAndNotDefault
                  ? 'Save changes'
                  : 'Save view as a shared list'}
              </StyledTextButton>
              {changesPendingSharedListsAndNotDefault && (
                <SecondaryTextButton
                  icon={<SaveIcon />}
                  onClick={() => handleShowSaveNewListModal(true)}
                >
                  Save as new shared list
                </SecondaryTextButton>
              )}
            </TopWrapper>
          )}
          {sharedLists &&
            hasSharedLists &&
            Object.keys(sharedLists).map((listName) => {
              return (
                <MenuItem
                  onClick={() => saveView(undefined, listName)}
                  key={listName}
                >
                  <NameWrapper>
                    {activeViewName === listName && (
                      <StyledCheckIcon size={18} />
                    )}
                    <ListName>{listName}</ListName>
                  </NameWrapper>
                  {isEditor ? (
                    <StyledClickPoint
                      ariaLabel="delete shared list"
                      onClick={() => deleteView(listName, 'sharedLists')}
                    >
                      <StyledDeleteIcon size={16} />
                    </StyledClickPoint>
                  ) : (
                    <LockIcon size={16} color={theme.COLORS.greys.mid} />
                  )}
                </MenuItem>
              )
            })}
        </ListWrapper>
        <FooterActions>
          {!isDefaultList && !isSharedList && (
            <TextButton
              onClick={() => handleShareList(activeView, activeView?.name)}
              icon={<ExportIcon size={16} />}
              data-cy="share-list-button"
            >
              Share list
            </TextButton>
          )}
          <StyledFileInputLabel htmlFor="import-list">
            <IconWrapper>
              <ImportIcon size={16} />
            </IconWrapper>
            Import list
          </StyledFileInputLabel>
          <StyledFileInput
            id="import-list"
            type="file"
            accept=".json"
            onChange={handleImportList}
          />
        </FooterActions>
      </MyListsMenuContentWrapper>
    </ClickAwayListener>
  )
}

export default MyListMenuContent
