import {
  CargoTypeEnum,
  OpenAreaEnum,
  VesselPoolEnum,
} from '~pages/pages-behind-login/position-list/types/exposure-types'

export const poolOptions = [
  { label: 'All', value: VesselPoolEnum.ALL },
  { label: 'City', value: VesselPoolEnum.CITY },
  { label: 'Intermediate', value: VesselPoolEnum.INTERMEDIATE },
  { label: 'Handy', value: VesselPoolEnum.HANDY },
  { label: 'MR', value: VesselPoolEnum.MR },
  { label: 'LR1', value: VesselPoolEnum.LR1 },
  { label: 'LR2', value: VesselPoolEnum.LR2 },
]

export const openAreaOptions = [
  { label: 'All', value: OpenAreaEnum.ALL },
  { label: 'AG (Fuj)', value: OpenAreaEnum.AG },
  { label: 'Americas (Houston)', value: OpenAreaEnum.AMERICAS },
  { label: 'MED (Malta)', value: OpenAreaEnum.MED },
  { label: 'North Asia (Yosu)', value: OpenAreaEnum.NORTH_ASIA },
  { label: 'NWE (Amsterdam)', value: OpenAreaEnum.NWE },
  { label: 'South Asia (Singapore)', value: OpenAreaEnum.SOUTH_ASIA },
]

export const cargoGroupOptions = [
  { label: 'All', value: 'ALL' },
  { label: 'CPP', value: CargoTypeEnum.CLEAN },
  { label: 'DPP', value: CargoTypeEnum.DIRTY },
]
