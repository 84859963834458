import { theme } from '@maersktankersdigital/web-components'
import { PaletteOptions } from '@mui/material'

const { COLORS } = theme
export const palette: PaletteOptions = {
  mode: 'light',
  common: {
    black: COLORS.primary.webFont,
    white: COLORS.primary.white,
  },
  primary: {
    main: COLORS.primary.maerskBlue,
    light: COLORS.secondary.hoverBlue,
    dark: COLORS.secondary.darkBlue.primary,
    contrastText: COLORS.primary.white,
  },
  error: {
    main: COLORS.red.mid.tint2,
    contrastText: COLORS.primary.webFont,
  },
  warning: {
    main: COLORS.orange.light.tint2,
    contrastText: COLORS.primary.webFont,
  },
  info: {
    main: COLORS.secondary.lightBlue.primary,
    contrastText: COLORS.primary.webFont,
  },
  success: {
    main: COLORS.citrus.mid.tint2,
    contrastText: COLORS.primary.webFont,
  },
}
