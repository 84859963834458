import { forwardRef, Ref } from 'react'

import { StyledHeading } from '~components/atoms/typography/heading/heading.styles'

import { PropsFromStyledComponent } from '../../../../utils/type-utils/styled'

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type HeadingProps = PropsFromStyledComponent<typeof StyledHeading>

const Heading = (
  { type, ...props }: HeadingProps,
  ref: Ref<HTMLHeadingElement>,
) => <StyledHeading ref={ref} as={type} type={type} {...props} />

export default forwardRef(Heading)
