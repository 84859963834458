import { theme } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

import { spacing } from '../global'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    primary: true
    secondary: true

    //default mui variants
    outlined: true
    elevation: false
  }
}
export interface MuiAccordionData {
  defaultProps?: ComponentsProps['MuiAccordion']
  styleOverrides?: ComponentsOverrides['MuiAccordion']
  variants?: ComponentsVariants['MuiAccordion']
}

export const MuiAccordion: MuiAccordionData = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        padding: `${spacing[3]}px`,
        borderRadius: '4px',
        '&:hover': {
          background: `${theme.COLORS.secondary.lightBlue.tint}`,
        },
        '&.Mui-expanded': {
          minHeight: 'auto',
          '& .Mui-expanded': {
            margin: 0,
          },
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '& .MuiAccordionDetails-root': {
        boxShadow: '0 0 25px rgb(0 0 0 / 8%)',
        padding: `${spacing[3]}px`,
        paddingBottom: 0,
      },
    },
  },
  variants: [
    {
      props: {
        variant: 'primary',
      },
      style: {
        '& .MuiAccordionSummary-root': {
          background: theme.COLORS.greys.ultraLight,

          '&.Mui-expanded': {
            backgroundColor: theme.COLORS.primary.white,
            border: `solid 1px ${theme.COLORS.primary.maerskBlue}`,
          },
        },
      },
    },
    {
      props: {
        variant: 'secondary',
      },
      style: {
        '& .MuiAccordionSummary-root': {
          background: theme.COLORS.greys.ultraLight,

          '&.Mui-expanded': {
            backgroundColor: theme.COLORS.greys.ultraLight,
            border: 'none',
          },
        },
        '& .MuiCollapse-root': { background: theme.COLORS.greys.ultraLight },
      },
    },
  ],
}
