import { lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ExactScopeName } from '~hooks/permissions/use-has-scope'
import { ExtendedRouteObject } from '../routes'

export const routesBehindLogin: ExtendedRouteObject[] = [
  {
    path: 'panel/faq',
    Component: lazy(() => import('../../pages/pages-behind-login/faq/faq')),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'panel/financials',
    Component: lazy(() => Promise.resolve({ default: () => <Outlet /> })),
    handle: {
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
        ExactScopeName.technicalManager,
      ],
    },

    children: [
      {
        index: true,
        Component: lazy(
          () => import('../../pages/pages-behind-login/financials/financials'),
        ),
      },
      {
        path: 'technical-management',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/technical-management/technical-management'
            ),
        ),
        handle: {
          restrictedTo: [ExactScopeName.technicalManager],
        },
      },
      {
        path: 'technical-management/:vessel',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/technical-management/bunker-adjustment/bunker-adjustment'
            ),
        ),
        handle: {
          restrictedTo: [ExactScopeName.technicalManager],
        },
        children: [
          {
            path: 'bunker-adjustment',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment'
                ),
            ),
            children: [
              {
                index: true,
                element: (
                  <Navigate to="bunker-adjustment-calculation" replace />
                ),
              },
              {
                path: 'bunker-adjustment-calculation',
                Component: lazy(
                  () =>
                    import(
                      '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment-calculation/bunker-adjustment-calculation'
                    ),
                ),
              },
              {
                path: 'trends',
                Component: lazy(
                  () =>
                    import(
                      '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/trends/trends'
                    ),
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'forecast/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/forecast/forecast'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'earnings/:pool',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/earnings/earnings'
            ),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="compare-vessels" replace />,
          },
          {
            path: 'compare-vessels',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/earnings/components/compare-vessels-tab/compare-vessels-tab'
                ),
            ),
          },
          {
            path: 'pool-vessels',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/earnings/components/pool-vessels-tab/pool-vessels-tab'
                ),
            ),
          },
        ],
      },
      {
        path: 'reports/:pool/:reportType',
        Component: lazy(
          () => import('../../pages/pages-behind-login/reports/report/report'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'reports/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/reports/reports'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'documents/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/documents/documents'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'emission-universe/:pool/:reportType',
        Component: lazy(
          () => import('../../pages/pages-behind-login/emissions/emission'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'emission-universe/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/emissions/emissions'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
    ],
  },

  {
    path: 'panel/financials/earnings/:pool/vessel/:vessel/partnerAxCode/:partnerAxCode',
    Component: lazy(
      () =>
        import(
          '../../pages/pages-behind-login/financials/vessel-financials/vessel-financials'
        ),
    ),
    handle: {
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
    },
    children: [
      {
        path: 'earnings',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/earnings/earnings'
            ),
        ),
      },
      {
        path: 'pool-points',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/pool-points/pool-points'
            ),
        ),
      },
      {
        path: 'scrubber-bonus',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/scrubber-bonus/scrubber-bonus'
            ),
        ),
      },
      {
        path: 'bunker-adjustment',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment'
            ),
        ),
        children: [
          {
            index: true,
            element: <Navigate to="bunker-adjustment-calculation" replace />,
          },
          {
            path: 'bunker-adjustment-calculation',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment-calculation/bunker-adjustment-calculation'
                ),
            ),
          },
          {
            path: 'trends',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/trends/trends'
                ),
            ),
          },
        ],
      },
    ],
  },

  {
    path: 'panel/news/:pool/:id',
    Component: lazy(
      () => import('../../pages/pages-behind-login/news/single-news'),
    ),
    handle: {
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/news/:pool',
    Component: lazy(() => import('../../pages/pages-behind-login/news/news')),
    handle: {
      withPools: true,
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/calendar/:pool',
    Component: lazy(
      () => import('../../pages/pages-behind-login/calendar/calendar-page'),
    ),
    handle: {
      withPools: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/contact',
    Component: lazy(
      () => import('../../pages/pages-behind-login/contact/contact'),
    ),
    handle: {
      withPools: false,
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/404',
    Component: lazy(() => import('../../pages/auth/unauthorized-page')),
    handle: {},
  },
]
