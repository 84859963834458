import styled from 'styled-components/macro'

export const StyledSideBarButtons = styled.div`
  display: flex;
  position: fixed;
  right: -121px;
  bottom: 178px;
  transform: rotate(-90deg);
  z-index: 1;
`
