import { isPast } from 'date-fns'
import * as React from 'react'
import { createContext, useContext, useMemo } from 'react'

import {
  SortOrder,
  usePositionListView,
} from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  CargoGroupEnum,
  PositionVesselData,
  VesselSpecsEnum,
  WorldArea,
} from '~pages/pages-behind-login/position-list/types/position-list-types'
import { VoyageStatus } from '~types/itinerary.types'

type State = {
  filteredData?: PositionVesselData[]
}

type Props = {
  children: React.ReactNode
  commercialManagementInitialData?: PositionVesselData[]
  initialData?: PositionVesselData[]
}

const negativeSpecValues = new Set([
  'No',
  'N/A',
  null,
  undefined,
  '',
  'no',
  'NO',
])

export const loadAreas = new Set([
  'GIBRALTAR',
  'HOUSTON',
  'AMSTERDAM',
  'SINGAPORE',
  'YANBU',
  'YOSU',
  'FUJAIRAH',
  'SIKKA',
  'MALTA OPL',
])

const mapCargoGroup = {
  [CargoGroupEnum.VEGETABLE_OILS]: CargoGroupEnum.CPP,
  [CargoGroupEnum.CHEMICALS]: CargoGroupEnum.CPP,
}

const FilteredDataStateContext = createContext<State | undefined>(undefined)

const FilteredDataProvider = ({
  children,
  initialData,
  commercialManagementInitialData,
}: Props) => {
  const { activeView, settings } = usePositionListView()

  const findNestedETA = (
    obj: PositionVesselData,
    targetValue: string,
  ): Date | null => {
    const isBallastVoyage = obj.isDuplicated || obj.isDuplicatedParent
    const distancesArray = isBallastVoyage
      ? obj.aisDistances.distances
      : obj.voyages?.[0]?.distances
    if (!Array.isArray(distancesArray) || !distancesArray.length) {
      return null
    }

    for (const item of distancesArray) {
      if (item.port_name === targetValue) {
        const eta = item['ETA']
        return eta ? new Date(eta) : null
      }
    }

    return null
  }

  const filteredSortedData = useMemo(() => {
    const filters = activeView?.filterSettings

    const mergedData = [
      ...(initialData || []),
      ...(commercialManagementInitialData || []),
    ]

    const duplicateBallastVessels: PositionVesselData[] = [...mergedData]
    // Iterate over the vessels and check for dischargePort in the latest voyage
    mergedData.forEach((vessel, index) => {
      const currentVoyage = vessel.voyages?.[0]
      if (!currentVoyage?.dischargePort) {
        // Find the last voyage with a truthy dischargePort
        const lastVoyageWithDischargePort = vessel.voyages.find(
          (voyage) => voyage.dischargePort,
        )

        // Business rules to determine if the vessel should be duplicated
        const currentVoyageCommenced =
          currentVoyage?.status === VoyageStatus.COMMENCED
        const lastItenerary = currentVoyage?.itineraries?.length - 1
        const lastIteneraryArrival =
          currentVoyage?.itineraries?.[lastItenerary]?.arrivalGmt
        const currentVoyageComplete = lastIteneraryArrival
          ? isPast(new Date(lastIteneraryArrival))
          : false
        const isRepairing = currentVoyage?.itineraries?.some(
          (itinerary) => itinerary.portFunction?.toUpperCase() === 'REPAIR',
        )

        const shouldNotDuplicate =
          (lastVoyageWithDischargePort &&
            lastVoyageWithDischargePort?.oprType === 'TCTO') ||
          !currentVoyageCommenced ||
          currentVoyageComplete ||
          isRepairing

        // If found, duplicate the vessel and update its worldArea, openDate and openPort
        if (!shouldNotDuplicate && lastVoyageWithDischargePort) {
          const duplicatedVessel = { ...vessel }
          duplicatedVessel.worldArea = lastVoyageWithDischargePort?.worldArea
          duplicatedVessel.openPort = lastVoyageWithDischargePort?.openPort
          duplicatedVessel.openDate = lastVoyageWithDischargePort?.openDate
          duplicatedVessel.isDuplicated = true
          vessel.isDuplicatedParent = true

          // Add the duplicated vessel to the vessels array
          duplicateBallastVessels.push(duplicatedVessel)
        }
      }
    })

    const favoriteVessels = settings?.showFavorites
      ? duplicateBallastVessels.filter((vessel) =>
          settings?.favoriteVessels?.includes(vessel.imoNumber),
        )
      : duplicateBallastVessels

    const dataWithAppliedFilters =
      favoriteVessels?.filter((item) => {
        for (const [key, values] of Object.entries(filters || {})) {
          const itemValue = item[key as keyof PositionVesselData]

          const latestVoyageWithCargoGroup = item.voyages.find(
            (voyage) => voyage.cargoGroup,
          ) || { cargoGroup: '' }

          if (!Array.isArray(values)) {
            if (key === 'vessel') {
              if (
                typeof itemValue === 'string' &&
                typeof values === 'string' &&
                itemValue?.toUpperCase() !== values?.toUpperCase()
              ) {
                return false
              }
              continue
            } else if (key === 'showDuplicates') {
              const isDuplicated = item.isDuplicated
              if (values === false && isDuplicated) {
                return false
              }
              continue
            }
            return true
          } else if (Array.isArray(values)) {
            const valueArray = values as string[]
            if (valueArray?.includes('ALL')) {
              continue
            }

            if (key === 'cargoGroup') {
              const cargoGroupToCheck: string =
                mapCargoGroup[
                  latestVoyageWithCargoGroup.cargoGroup as keyof typeof mapCargoGroup
                ] ?? latestVoyageWithCargoGroup.cargoGroup
              if (
                cargoGroupToCheck &&
                !valueArray?.includes(cargoGroupToCheck)
              ) {
                return false
              }
              continue
            }

            if (key === 'worldArea') {
              const levelFilter = values.map((value: string) =>
                value.split('.'),
              )
              const hasWorldArea =
                item.worldArea && Object.keys(item.worldArea).length > 0
              const areaMatches = levelFilter.some(([level, areaFilter]) => {
                const levelValue = item.worldArea?.[level as keyof WorldArea]
                return levelValue === areaFilter
              })
              if (!areaMatches && hasWorldArea) {
                return false
              }
              continue
            }

            if (key === 'vesselSpecs') {
              for (const spec of filters?.vesselSpecs || []) {
                if (spec === VesselSpecsEnum.ALL) continue
                if (negativeSpecValues.has(item[spec])) {
                  return false
                }
              }
              continue
            }

            if (
              values &&
              !valueArray?.includes(
                typeof itemValue === 'string' ? itemValue?.toUpperCase() : '',
              )
            ) {
              return false
            }

            continue
          }
        }

        return true
      }) ?? []

    const dataWithLatestComments = dataWithAppliedFilters?.map((vessel) => {
      const filteredComments = vessel.comments?.filter(
        (comment) => comment.tab === 'positionlist',
      )

      const newestComment = filteredComments?.length
        ? filteredComments.reduce((newest, current) => {
            const newestDate =
              newest.updatedAt || newest.createdAt || new Date()
            const currentDate =
              current.updatedAt || current.createdAt || new Date()
            return new Date(currentDate) > new Date(newestDate)
              ? current
              : newest
          })
        : null

      return {
        ...vessel,
        comments: newestComment ? [newestComment] : [],
      } as PositionVesselData
    })

    const { column, order } = activeView?.sortColumn || {}
    if (!column) return dataWithLatestComments
    const isLoadArea = loadAreas.has(column)
    const isLevel = /^level/.test(column)
    const openFields = ['openDate', 'openPort']
    const isNextDryDock = column === 'nextDryDock'

    const sortedData = [...dataWithLatestComments].sort((a, b) => {
      let aValue, bValue

      if (isLoadArea) {
        aValue = findNestedETA(a, column)
        bValue = findNestedETA(b, column)
      } else if (isLevel) {
        aValue = a.worldArea[column as keyof WorldArea]
        bValue = b.worldArea[column as keyof WorldArea]
      } else if (isNextDryDock) {
        aValue = a?.nextDryDockInternal?.nextDryDock || a.nextDryDock
        bValue = b?.nextDryDockInternal?.nextDryDock || b.nextDryDock
      } else {
        aValue = a[column]
        bValue = b[column]
      }

      if (typeof aValue === 'string') aValue = aValue.toLowerCase()
      if (typeof bValue === 'string') bValue = bValue.toLowerCase()

      if (order === SortOrder.ASC && aValue && bValue) {
        return aValue > bValue ? 1 : -1
      }
      return aValue && bValue && aValue < bValue ? 1 : -1
    })

    const sortedWithEmptyOpenFieldsOnTop = [...sortedData].sort((a, b) => {
      for (const field of openFields) {
        if (!a[field as keyof PositionVesselData]) return -1
        if (!b[field as keyof PositionVesselData]) return 1
      }
      return 0
    })

    return sortedWithEmptyOpenFieldsOnTop
  }, [
    initialData,
    commercialManagementInitialData,
    activeView?.filterSettings,
    activeView?.sortColumn,
    settings?.showFavorites,
    settings?.favoriteVessels,
  ])

  const value = useMemo(() => {
    return {
      filteredData: filteredSortedData,
    }
  }, [filteredSortedData])

  return (
    <FilteredDataStateContext.Provider value={value}>
      {children}
    </FilteredDataStateContext.Provider>
  )
}

function useFilteredData() {
  const context = useContext(FilteredDataStateContext)

  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider')
  }

  return context
}

export { FilteredDataProvider, useFilteredData }
