import {
  Header,
  TopBar,
  useBreakpoint,
} from '@maersktankersdigital/web-components'
import { AppBar } from '@mui/material'
import { useMatches } from 'react-router-dom'
import { SideBarButtons } from '~components/molecules/side-bar-buttons/side-bar-buttons'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useGetUsersVT } from '~hooks/queries/users/use-get-users-vt'
import { useGetVessels } from '~hooks/queries/vessels/use-get-vessels'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { PageRoute } from '~routing/constants/page-route'
import { useDynamicTitle } from './hooks/use-dynamic-title'
import { useSubheaderLinks } from './hooks/use-subheader-links'

export const isV1page = true

export function TopNavigation() {
  const { isMobileSize } = useBreakpoint()
  const { topNavigationHeight } = useNavAndFooterConstants()
  const matches = useMatches()
  const subheaderLinks = useSubheaderLinks()
  const routeWithLeftNavigation = matches.some(
    // @ts-ignore
    (x) => x.handle && !x.handle.disableLeftNavigation,
  )

  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isCharter = useHasScope(ExactScopeName.charter)

  const dashboardLink = useConstructLinkWithPool(
    '/panel/dashboard/:pool',
    isV1page,
  )

  const myFleetTitle = useDynamicTitle(
    'my fleet',
    PageRoute.VESSELS,
    useGetVessels,
  )
  const usersTitle = useDynamicTitle('users', PageRoute.USERS, useGetUsersVT)

  const topBarHeaders: Header[] = [
    ...(isTechnicalManager
      ? []
      : [
          {
            title: 'dashboard',
            link: `${window.location.origin}${dashboardLink}`,
            isExternal: false,
          },
        ]),
    {
      title: 'financials',
      link: 'panel/financials',
    },
    { title: myFleetTitle, link: '/vessels' },
    ...(!isMtUser
      ? []
      : [
          { title: 'position list', link: PageRoute.POSITION_LIST },
          ...(isCharter
            ? []
            : [
                {
                  title: usersTitle,
                  link: PageRoute.USERS,
                },
              ]),
        ]),
  ]

  return (
    <>
      <AppBar
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: topNavigationHeight,
          '& a': {
            textDecoration: 'none',
          },
        }}
      >
        <TopBar
          headers={topBarHeaders}
          subHeaders={subheaderLinks}
          isHamburgerMenu={isMobileSize && routeWithLeftNavigation}
        />
      </AppBar>
      <SideBarButtons isVisible />
    </>
  )
}
