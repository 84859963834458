import styled, { CSSObject, css } from 'styled-components/macro'
import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

export type ParagraphSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'

type StyledParagraphProps = {
  color?: typeof Colors
  size?: ParagraphSize
  weight?: 'bold' | 'normal'
}

const fontSizeConfig: { [Size in ParagraphSize]: CSSObject } = {
  xSmall: {
    fontSize: Fonts.sizes.caption,
    // Add margins/line-heights etc. here
  },
  small: {
    fontSize: Fonts.sizes.small,
  },
  medium: {
    fontSize: Fonts.sizes.body,
  },
  large: {
    fontSize: Fonts.sizes.large,
  },
  xLarge: {
    fontSize: Fonts.sizes.xLarge,
  },
}

export const StyledParagraph = styled.p<StyledParagraphProps>`
  line-height: 1;

  ${(p) => css`
    font-family: ${p.weight === 'bold'
      ? Fonts.families.bold
      : Fonts.families.regular};
    font-size: ${p.size
      ? fontSizeConfig[p.size].fontSize
      : fontSizeConfig.small.fontSize};
    color: ${p.color ?? 'inherit'};
  `};
`
