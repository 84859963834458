//@ts-nocheck
import {
  isWithinInterval,
  addWeeks,
  endOfWeek,
  startOfWeek,
  getWeek,
} from 'date-fns'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  OpenMtVessels,
  OpenVesselData,
  SortableColumn,
} from '~api/gql/generated/graphql'
import { useUserSettings } from '~pages/pages-behind-login/position-list/contexts/user-settings-provider'
import {
  CargoTypeEnum,
  OpenAreaEnum,
  VesselPoolEnum,
} from '~pages/pages-behind-login/position-list/types/exposure-types'

export interface FilteredData {
  openVessels?: OpenVessels[]
  totalVesselCount?: number
}

export interface CargoGroupCount {
  clean: number
  cleanWithUnknown: number
  dirty: number
  dirtyWithUnknown: number
  total: number
  unknown: number
}

export interface OpenVessels {
  exposureArea: string
  Maersk: OpenVesselData[]
  vesselCount: CargoGroupCount
  vesselPercentage: CargoGroupCount
  vessels?: OpenVesselData[]
  fromDate: string
}

type State = {
  clearFilters: () => void
  clearPersistedFilters: () => void
  filteredData?: FilteredData | undefined
  filters?: FilterTypes | undefined
  showBothCargoGroups?: boolean
  persistFilters: () => void
  saveFilters: (
    name: keyof FilterTypes,
    value: string[] | string | boolean,
  ) => void
}

export interface FilterTypes {
  cargoGroup: CargoTypeEnum[]
  exposureArea?: OpenAreaEnum[]
  pool: VesselPoolEnum[]
  showPercentage?: boolean
  weeks?: string[]
  totalExposure?: boolean
}

const ExposureFilterStateContext = createContext<State | undefined>(undefined)

interface Props {
  data?: MtOpenVesselsResponse
}

function ExposureFilterProvider({ children, data }: PropsWithChildren<Props>) {
  const defaultFilters = useMemo(() => {
    return {
      exposureArea: [OpenAreaEnum.ALL],
      weeks: ['ALL'],
      cargoGroup: [CargoTypeEnum.ALL],
      pool: [VesselPoolEnum.ALL],
      showPercentage: false,
      totalExposure: false,
    }
  }, [])

  const { userSettings, saveUserSettings } = useUserSettings()

  const [filters, setFilters] = useState<FilterTypes>(defaultFilters)

  // Set filters from userSettings storage
  useEffect(() => {
    if (userSettings?.filterSettings) {
      const { filterSettings } = userSettings

      setFilters((prevFilters) => ({
        ...prevFilters,
        worldArea:
          (filterSettings.exposureArea as OpenAreaEnum[]) ||
          prevFilters.exposureArea,
        pool: (filterSettings.pool as VesselPoolEnum[]) || prevFilters.pool,
        showPercentage:
          filterSettings.showPercentage ?? prevFilters.showPercentage,
        cargoGroup:
          (filterSettings.cargoGroup as CargoTypeEnum[]) ||
          prevFilters.cargoGroup,
        totalExposure:
          filterSettings.totalExposure ?? prevFilters.totalExposure,
      }))
    }
  }, [userSettings])

  const saveFilters = useCallback(
    (
      name: keyof FilterTypes,
      value: string | string[] | boolean | SortableColumn,
    ) => {
      setFilters((prevFilters) => {
        const isValueArray = Array.isArray(value)
        let newFilterValue
        if (isValueArray) {
          const allIndex = value.indexOf('ALL')
          // If no filters are selected, add the 'ALL' option
          if (value.length === 0) {
            newFilterValue = ['ALL']
          }
          // If only ALL is selected and it's the last option selected, set the filter to only ALL
          else if (allIndex > -1 && allIndex === value.length - 1) {
            newFilterValue = ['ALL']
          } else {
            // If another option is selected, remove ALL from the filters
            newFilterValue = value.filter((val) => val !== 'ALL')
          }
        } else {
          // If the value is a string (not array), set the filter to the value
          newFilterValue = value
        }

        return { ...prevFilters, [name]: newFilterValue }
      })
    },
    [],
  )

  const persistFilters = useCallback(() => {
    saveUserSettings({
      ...userSettings,
      filterSettings: {
        ...userSettings?.filterSettings,
        exposureArea: filters.exposureArea,
        showPercentage: filters.showPercentage,
        pool: filters.pool,
        cargoGroup: filters.cargoGroup,
        totalExposure: filters.totalExposure,
      },
    })
  }, [filters, saveUserSettings, userSettings])

  const clearFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [defaultFilters])

  const clearPersistedFilters = useCallback(() => {
    clearFilters()
    saveUserSettings({ ...userSettings, filterSettings: {} })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters, saveUserSettings])

  const filterByWeeks = (item: OpenMtVessels) => {
    // If total exposure is selected, show all future weeks
    if (filters.totalExposure) {
      return true
    }

    if (!filters.weeks) return false
    const weekNumber = getWeek(new Date(item.fromDate))

    const weekOne = new Date(
      startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0, 0),
    )
    const weekFour = endOfWeek(addWeeks(weekOne, 3))
    const itemDate = new Date(item.fromDate)

    // Check if the item's date is within the interval of current week and the next three weeks
    const isWithinRange = isWithinInterval(itemDate, {
      start: weekOne,
      end: weekFour,
    })

    if (!isWithinRange) {
      return false
    }

    return (
      filters.weeks.includes('ALL') ||
      filters.weeks.includes(String(weekNumber))
    )
  }

  const filterByWorldArea = (item: OpenMtVessels) =>
    !filters?.exposureArea ||
    filters?.exposureArea?.includes(OpenAreaEnum.ALL) ||
    filters?.exposureArea?.includes(item.exposureArea as OpenAreaEnum)

  const filterByCargoGroup = (vessel: OpenVesselData) => {
    return (
      filters?.cargoGroup.includes(vessel.cargoGrade as CargoTypeEnum) ||
      filters?.cargoGroup.includes(CargoTypeEnum.ALL) ||
      // Some vessels don't have a cargo grade, they should always be included
      !vessel.cargoGrade
    )
  }

  const filterByPool = (vessel: OpenVesselData) => {
    return (
      filters?.pool.includes(vessel.pool as VesselPoolEnum) ||
      filters?.pool.includes(VesselPoolEnum.ALL)
    )
  }

  const filteredOpenVessels = data?.openVessels.filter(
    (item) => filterByWeeks(item) && filterByWorldArea(item),
  )

  const filteredVessels: OpenVessels[] =
    filteredOpenVessels?.map((openVessel) => {
      const filteredMaerskVessels = openVessel.Maersk.filter(
        (vessel) => filterByCargoGroup(vessel) && filterByPool(vessel),
      )

      const { cleanCount, dirtyCount, unknownCount } =
        filteredMaerskVessels.reduce(
          (acc, vessel) => {
            if (vessel.cargoGrade === CargoTypeEnum.CLEAN) {
              acc.cleanCount++
            } else if (vessel.cargoGrade === CargoTypeEnum.DIRTY) {
              acc.dirtyCount++
            } else {
              acc.unknownCount++
            }
            return acc
          },
          { cleanCount: 0, dirtyCount: 0, unknownCount: 0 },
        )

      const totalCount = data.totalCount || 1 // Ensure totalCount is not zero

      const vesselCount = {
        total: cleanCount + dirtyCount + unknownCount,
        clean: cleanCount,
        dirty: dirtyCount,
        unknown: unknownCount,
        cleanWithUnknown: cleanCount + unknownCount,
        dirtyWithUnknown: dirtyCount + unknownCount,
      }

      const vesselPercentage = {
        total: vesselCount.total ? (vesselCount.total / totalCount) * 100 : 0,
        clean: cleanCount ? (cleanCount / totalCount) * 100 : 0,
        dirty: dirtyCount ? (dirtyCount / totalCount) * 100 : 0,
        unknown: unknownCount ? (unknownCount / totalCount) * 100 : 0,
        cleanWithUnknown: vesselCount.cleanWithUnknown
          ? (vesselCount.cleanWithUnknown / totalCount) * 100
          : 0,
        dirtyWithUnknown: vesselCount.dirtyWithUnknown
          ? (vesselCount.dirtyWithUnknown / totalCount) * 100
          : 0,
      }

      return {
        ...openVessel,
        Maersk: filteredMaerskVessels,
        vesselCount,
        vesselPercentage,
      }
    }) || []

  const showBothCargoGroups = useMemo(() => {
    return (
      filters?.cargoGroup?.includes(CargoTypeEnum.ALL) ||
      (filters?.cargoGroup.includes(CargoTypeEnum.CLEAN) &&
        filters?.cargoGroup.includes(CargoTypeEnum.DIRTY))
    )
  }, [filters?.cargoGroup])

  const filteredData = {
    openVessels: filteredVessels,
    totalVesselCount: data?.totalCount,
  }

  const value = {
    filters,
    saveFilters,
    persistFilters,
    clearPersistedFilters,
    clearFilters,
    filteredData,
    showBothCargoGroups,
  }

  return (
    <ExposureFilterStateContext.Provider value={value}>
      {children}
    </ExposureFilterStateContext.Provider>
  )
}

function useExposureFilters() {
  const context = useContext(ExposureFilterStateContext)
  if (context === undefined) {
    throw new Error(
      'useExposureFilters must be used within a ExposureFilterProvider',
    )
  }
  return context
}

export { ExposureFilterProvider, useExposureFilters }
