import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { ColorVariants } from '~components/atoms/color-label-short/color-label-short'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

import { CII } from '~hooks/queries/vessels/data/types/vessel-dashboard'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { PortFunction } from '~types/itinerary.types'

const colors: Record<ColorVariants, Record<PortFunction & CII, string>> = {
  portFunction: {
    [PortFunction.COMMENCING]: theme.COLORS.greys.ultraDark,
    [PortFunction.FUELING]: theme.COLORS.green.mid.primary,
    [PortFunction.LOADING]: theme.COLORS.citrus.dark.primary,
    [PortFunction.DISCHARGING]: theme.COLORS.red.mid.tint1,
    [PortFunction.OTHER]: theme.COLORS.greys.mid,
    [PortFunction.WAITING]: theme.COLORS.green.dark.primary,
    [PortFunction.CANAL_TRANSIT]: theme.COLORS.green.mid.tint1,
    [PortFunction.REPAIR]: theme.COLORS.feedback.red.error,
    [PortFunction.CHARTERERS_ORDERS]: theme.COLORS.green.dark.tint2,
    [PortFunction.TERMINATING]: theme.COLORS.red.dark.primary,
    [PortFunction.DELIVERY]: theme.COLORS.red.dark.tint2,
    [PortFunction.FOR_ORDERS]: theme.COLORS.green.dark.tint1,
    [PortFunction.MULTI_PURPOSE]: Colors.greys.base,
    [PortFunction.REDELIVERY]: theme.COLORS.red.dark.tint1,
    [PortFunction.PASSING]: theme.COLORS.greys.dark,
  },
  CII: {
    [CII.A]: theme.COLORS.citrus.dark.primary,
    [CII.B]: theme.COLORS.citrus.light.primary,
    [CII.C]: theme.COLORS.orange.light.primary,
    [CII.D]: theme.COLORS.orange.mid.primary,
    [CII.E]: theme.COLORS.feedback.red.error,
  },
}

type CommonProps = {
  size: 'small' | 'medium'
}

export const StyledColorLabelShort = styled.div<
  CommonProps &
    (
      | {
          variant: ColorVariants
          variantType: string
        }
      | {
          color: string
        }
    )
>`
  --size: ${(p) => (p.size === 'small' ? '16px' : '30px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) =>
    'variant' in p && 'variantType' in p
      ? colors[p.variant as ColorVariants][p.variantType as PortFunction & CII]
      : 'color' in p
        ? p.color
        : 'inherit'};
  width: var(--size);
  height: var(--size);
  border-radius: ${({ size }) => (size === 'small' ? '2px' : '6px')};
  flex-shrink: 0;

  ${StyledParagraph} {
    color: white;
  }
`
