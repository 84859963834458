import { CII } from '~hooks/queries/vessels/data/types/vessel-dashboard'
import { Distance, Voyage } from '~types/itinerary.types'

export interface ResponsibleUser {
  name: string
  sub: string
}

export interface Rejection {
  canImpairEarnings: boolean
  closedBy: string
  closingDate: string
  hash: string
  isOngoing: boolean
  oilMajor: string
  rejectionCause: string
  rejectionDate: string
  responsibleUser: ResponsibleUser
  updatedAt: string
  updatedBy: string
}

export interface User {
  email: string
  name: string
}

export interface IComment {
  comment?: string
  createdAt?: string
  field?: string
  hash?: string
  responsibleUser?: User
  tab?: string
  updatedAt?: string
  updatedBy?: string
  updatedResponsibleUser?: User
}

export interface UsCoc {
  issued?: string
  lastAnnual?: string
}

export interface WorldArea {
  level1: string
  level2: string
  level3: string
}

export interface Consumption {
  ladenTonsPerDay: number
  ladenTonsPerDayPoolAvg: number
  ladenTonsPerDayPoolRating: ConsumptionLevel
}

export interface InternalDryDock {
  comment?: string
  createdAt: string
  nextDryDock: string
  responsibleUser: User
}

export interface LastSire {
  approval: string
  inspectionDate: string
  port: string
}

export interface PositionListVoyage extends Voyage {
  actualEstimatedDaysDelta: number
  actualImpairedEarnings: ActualImpairedEarnings
  actualImpairedEarningsComment: string
  actualTce: number
  actualTceDelta: number
  estimatedTce: number
  openDate: string
  openPort: string
  oprType: 'TCOV' | 'TCTO'
  worldArea: WorldArea
}

export interface LastThreeCargoes {
  comment?: string
  createdAt?: string
  lastThreeCargoes?: string
  responsibleUser?: User
}

export enum ActualImpairedEarnings {
  IMPARED = 'IMPARED',
  ACTUAL = 'ACTUAL',
}

export interface PositionVesselData {
  beam: string
  bunkerConsumption: Consumption
  bwts: string
  cbm: string
  ciiRating: CII
  comments: IComment[]
  dpp: string
  dwt: string
  flag: string
  iceClass: string
  igs: string
  igsSupply: string
  imoClass: string
  imoNumber: string
  isDuplicated?: boolean
  isDuplicatedParent?: boolean
  lastSire: LastSire
  lastThreeCargoesInternal: LastThreeCargoes
  lastUpdateGmt: string
  loa: string
  mouldedDepth: string
  n2: string
  nextDryDock: string
  nextDryDockInternal: InternalDryDock
  openDate: string
  openInt: string
  openPort: string
  operator: string
  pbl: string
  pool: string
  redeliveryDate: string
  rejections: Rejection[]
  scrubber: string
  status: StatusValues
  sternLine: string
  summerDraft: string
  tankCoating: string
  tradeAreaName: string
  usCoc: UsCoc
  vessel: string
  vesselId: string
  vesselStatus: string
  voyages: PositionListVoyage[]
  worldArea: WorldArea
  yearBuilt: string
  aisDistances: AISDistances
}

export interface AISDistances {
  aisPositionReceived: string
  distances: Distance[]
  latitude: number
  longtiude: number
}

export enum ConsumptionLevel {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum StatusValues {
  OPEN = 'open',
  FOR_ORDERS = 'forOrders',
  IN_BALLAST = 'inBallast',
  CLEANING = 'cleaning',
  OFFERED = 'offered',
  ON_SUBS = 'onSubs',
  UNCERTAIN = 'uncertain',
  ON_HOLD = 'onHold',
  DRY_DOCK = 'dryDock',
  TC_OUT = 'tcOut',
  REDELIVERY = 'redelivery',
  FIXED = 'fixed',
}

export type ColumnDefinition = {
  columnLabel?: string
  fixed?: boolean
  label: string
  permanent?: boolean
  sortable: boolean
  value: string
  width?: number
}

export enum VesselClassEnum {
  ALL = 'ALL',
  HANDY = 'HANDY',
  MR = 'MR',
  LR1 = 'LR1',
  LR2 = 'LR2',
  INTERMEDIATE = 'INTERMEDIATE',
  CITY = 'CITY',
}

export enum CargoGroupEnum {
  ALL = 'ALL',
  CPP = 'CLEAN PETROLEUM PRODUCTS',
  DPP = 'DIRTY PETROLEUM PRODUCTS',
  VEGETABLE_OILS = 'VEGETABLE OILS',
  CHEMICALS = 'CHEMICALS',
}

export enum VesselSpecsEnum {
  ALL = 'ALL',
  STERN_LINE = 'sternLine',
  BWTS = 'bwts',
  ICE_CLASS = 'iceClass',
  IGS = 'igsSupply',
  IMO_CLASS = 'imoClass',
  SCRUBBER = 'scrubber',
}
