import { Box as MuiBox, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { mutate } from 'swr'
import { ApiClient, ApiRoutes } from '~api'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { generatePathName } from '~utils/generate-path-name'
import { gtm } from '~utils/gtm'
import { useStore } from '../../../../context/store-provider'
import { StyledFavoritesToggler } from './styles'

interface VesselFavoriteTogglerProps {
  showText?: boolean
  vesselId: string
  vesselName: string
}

const VesselFavoriteToggler: React.FC<VesselFavoriteTogglerProps> = ({
  vesselName,
  vesselId,
  showText = true,
}) => {
  const { Favorites } = useStore()
  const isFavorite = Favorites.favoriteVessels.includes(vesselId)
  const [updateCount, setUpdateCount] = useState(0)

  useEffect(() => {
    if (updateCount === 0) return

    ApiClient.User.Settings.edit({
      settings: {
        vessels: {
          favourites: Favorites.favoriteVessels,
        },
      },
    })
      .then(() => {
        mutate(generatePathName(ApiRoutes.User.Info.read))
      })
      .catch((e) => {
        console.log(e)
      })
  }, [updateCount])

  return (
    <StyledFavoritesToggler>
      <Box dataTest="favorite-toggler" noStyles>
        <MuiBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (isFavorite) {
              Favorites.removeFavoriteVessel(vesselId)
            } else {
              Favorites.addFavoriteVessel(vesselId)

              gtm.pushEvent('internal_click', {
                click_type: 'vessel_favorited',
                click_subtype: vesselName,
              })
            }

            setUpdateCount(updateCount + 1)
          }}
        >
          <Icon
            name={isFavorite ? 'starFilled' : 'star'}
            fill={Colors.body}
            size={18}
          />

          {showText && (
            <Typography sx={{ ml: 2 }} component="span">
              {isFavorite ? 'Unmark as favorite' : 'Mark as favorite'}
            </Typography>
          )}
        </MuiBox>
      </Box>
    </StyledFavoritesToggler>
  )
}

export default VesselFavoriteToggler
