import { theme } from '@maersktankersdigital/web-components'

import { breakpoints } from './breakpoints'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: React.CSSProperties
    h2: React.CSSProperties
    h3: React.CSSProperties
    h4: React.CSSProperties
    link: React.CSSProperties
    paragraph1: React.CSSProperties
    paragraph1Secondary: React.CSSProperties
    paragraphBold1: React.CSSProperties
    paragraphBoldLarge: React.CSSProperties
    paragraph2: React.CSSProperties
    paragraphBold2: React.CSSProperties
    caption: React.CSSProperties
    captionAmount: React.CSSProperties
    menu: React.CSSProperties
    label: React.CSSProperties
    graphCaption: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties
    h2?: React.CSSProperties
    h3?: React.CSSProperties
    h4?: React.CSSProperties
    link?: React.CSSProperties
    paragraph1?: React.CSSProperties
    paragraph1Secondary?: React.CSSProperties
    paragraphBold1?: React.CSSProperties
    paragraphBoldLarge?: React.CSSProperties
    paragraph2?: React.CSSProperties
    paragraphBold2?: React.CSSProperties
    caption?: React.CSSProperties
    captionAmount?: React.CSSProperties
    menu?: React.CSSProperties
    label?: React.CSSProperties
    graphCaption?: React.CSSProperties
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true
    h2: true
    h3: true
    h4: true
    link: true
    paragraph1: true
    paragraph1Secondary: true
    paragraphBold1: true
    paragraphBoldLarge: true
    paragraph2: true
    paragraphBold2: true
    caption: true
    captionAmount: true
    menu: true
    label: true
    graphCaption: true

    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    overline: false
  }
}

const h1 = {
  color: theme.COLORS.secondary.darkBlue.primary,
  fontFamily: 'MaerskHeadlineLight',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  [`@media (min-width: ${breakpoints.values.sm}px)`]: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
}

const h2 = {
  color: theme.COLORS.secondary.darkBlue.primary,
  fontFamily: 'MaerskHeadlineRegular',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  [`@media (min-width: ${breakpoints.values.sm}px)`]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
}

const h3 = {
  color: theme.COLORS.secondary.darkBlue.primary,
  fontFamily: 'MaerskTextRegular',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
}

const h4 = {
  color: theme.COLORS.secondary.darkBlue.primary,
  fontFamily: 'MaerskTextRegular',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '1px',
  textTransform: 'uppercase',
}

const link = {
  fontFamily: 'MaerskTextRegular',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  color: theme.COLORS.primary.maerskBlue,
}

const paragraph1 = {
  fontFamily: 'MaerskTextRegular',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.COLORS.secondary.darkBlue.primary,
}

const paragraph1Secondary = {
  ...paragraph1,
  color: theme.COLORS.greys.dark,
}

const paragraphBold1 = {
  ...paragraph1,
  fontFamily: 'MaerskTextBold',
}

const paragraphBoldLarge = {
  ...h3,
  fontFamily: 'MaerskTextBold',
}

const paragraph2 = {
  color: theme.COLORS.secondary.darkBlue.primary,
  fontFamily: 'MaerskTextRegular',
  fontSize: '0.875rem',
  lineHeight: '1rem',
}

const paragraphBold2 = {
  ...paragraph2,
  fontFamily: 'MaerskTextBold',
}

const caption = {
  ...paragraph2,
  fontSize: '0.75rem',
}

const menu = {
  ...caption,
  letterSpacing: '1.2px',
  textTransform: 'uppercase',
}

const label = {
  ...caption,
  fontFamily: 'MaerskHeadlineBold',
  textTransform: 'uppercase',
}

const captionAmount = {
  ...caption,
  fontFamily: 'RobotoRegular',
}

const graphCaption = {
  fontFamily: 'MaerskTextRegular',
  fontSize: '0.625rem',
  lineHeight: '0.75rem',
  letterSpacing: '0.7px',
  textTransform: 'uppercase',
  color: theme.COLORS.greys.dark,
  fill: theme.COLORS.greys.dark, // fill is needed for setting the color of the <text> inside of SVG element
}

export const typography = {
  fontFamily: [
    'MaerskTextLight',
    'MaerskTextRegular',
    'MaerskTextBold',
    'MaerskHeadlineLight',
    'MaerskHeadlineRegular',
    'MaerskHeadlineBold',
  ].join(','),
  h1,
  h2,
  h3,
  h4,
  link,
  paragraph1,
  paragraph1Secondary,
  paragraphBold1,
  paragraphBoldLarge,
  paragraph2,
  paragraphBold2,
  caption,
  captionAmount,
  menu,
  label,
  graphCaption,
}
