import { theme } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

export interface MuiSkeletonData {
  defaultProps?: ComponentsProps['MuiSkeleton']
  styleOverrides?: ComponentsOverrides['MuiSkeleton']
  variants?: ComponentsVariants['MuiSkeleton']
}

export const MuiSkeleton: MuiSkeletonData = {
  styleOverrides: {
    root: {
      backgroundColor: theme.COLORS.greys.light,
    },
  },
}
