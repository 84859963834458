/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation upsertUser($userID: String!, $userSettings: UserSettingsInput!) {\n    upsertUser(userID: $userID, userSettings: $userSettings) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          pool\n          cargoGroup\n        }\n        vesselColumns\n      }\n    }\n  }\n':
    types.UpsertUserDocument,
  '\n  query getMTOpenVessels {\n    getMTOpenVessels {\n      openVessels {\n        exposureArea\n        fromDate\n        Maersk {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n      totalCount\n      latestCreatedDate\n      totalExposure {\n        exposureArea\n        vessels {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n    }\n  }\n':
    types.GetMtOpenVesselsDocument,
  '\n  query getUser($userID: String!) {\n    getUser(userID: $userID) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          showPercentage\n          pool\n          cargoGroup\n          totalExposure\n        }\n        vesselColumns\n      }\n    }\n  }\n':
    types.GetUserDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertUser($userID: String!, $userSettings: UserSettingsInput!) {\n    upsertUser(userID: $userID, userSettings: $userSettings) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          pool\n          cargoGroup\n        }\n        vesselColumns\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertUser($userID: String!, $userSettings: UserSettingsInput!) {\n    upsertUser(userID: $userID, userSettings: $userSettings) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          pool\n          cargoGroup\n        }\n        vesselColumns\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getMTOpenVessels {\n    getMTOpenVessels {\n      openVessels {\n        exposureArea\n        fromDate\n        Maersk {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n      totalCount\n      latestCreatedDate\n      totalExposure {\n        exposureArea\n        vessels {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getMTOpenVessels {\n    getMTOpenVessels {\n      openVessels {\n        exposureArea\n        fromDate\n        Maersk {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n      totalCount\n      latestCreatedDate\n      totalExposure {\n        exposureArea\n        vessels {\n          cargoGrade\n          ID\n          loadingAreaETA\n          localLoadingAreaETA\n          localOpenDate\n          openDate\n          openPort\n          openRegion\n          openWorldArea\n          pool\n          vesselId\n          vesselName\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUser($userID: String!) {\n    getUser(userID: $userID) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          showPercentage\n          pool\n          cargoGroup\n          totalExposure\n        }\n        vesselColumns\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUser($userID: String!) {\n    getUser(userID: $userID) {\n      userID\n      userSettings {\n        filterSettings {\n          exposureArea\n          showPercentage\n          pool\n          cargoGroup\n          totalExposure\n        }\n        vesselColumns\n      }\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
