export const Fonts = {
  // TODO: Add fallbacks here?
  families: {
    light: 'MaerskTextLight',
    regular: 'MaerskTextRegular',
    bold: 'MaerskTextBold',
    'headline-light': 'MaerskHeadlineLight',
    headline: 'MaerskHeadlineRegular',
    'headline-bold': 'MaerskHeadlineBold',
    headlines: {
      light: 'MaerskHeadlineLight',
      regular: 'MaerskHeadlineRegular',
      bold: 'MaerskHeadlineBold',
    },
  },
  sizes: {
    caption: '12px',
    small: '14px',
    body: '16px',
    large: '20px',
    xLarge: '26px',
    h6: '20px',
    h5: '26px',
    h4: '34px',
    h3: '40px',
    h2: '50px',
    h1: '60px',
  },
  lineHeights: {
    caption: '20px',
    small: '24px',
    body: '24px',
    h6: '26px',
    h5: '32px',
    h4: '38px',
    h3: '46px',
    h2: '56px',
    h1: '66px',
  },
}

export const Colors = {
  black: '#000000',
  blues: {
    base: '#00233d',
    dark: '#00243D',
    deep: '#003e5e',
    mid: '#0073ab',
    maersk: '#42b0d5',
    light: '#b5e0f4',
    secondaryLight: '#f0f9fd',
    lighter: '#e9f6fc',
    tableSubsection: '#b5e0f433',
    tableExpanded: '#b5e0f41a',
    secondaryDark: '#193850',
  },
  body: '#282828',
  greys: {
    darker: '#545454',
    dark: '#878787',
    table: '#EDEDED',
    base: '#cfcfcf',
    light: '#d5d5d5',
    lighter: '#ededed',
    lightest: '#f7f7f7',
    ultraLight: '#f7f8f980',
    ultraDark: '#405a6e',
    sidebar: '#fafafa',
  },
  greens: {
    lighter: '#a3bf00',
    light: '#8ea532',
    base: '#7c9d00',
    teal: '#4DB5AB',
  },
  oranges: {
    light: '#FBE7C3',
    base: '#ffa201',
    dark: '#f07508',
    brick: '#e9735d',
  },
  reds: {
    base: '#fa381c',
    dark: '#6d0d05',
    error: '#b80012',
    light: '#fcefeb',
  },
  yellows: {
    base: '#d6e000',
    light: '#dce5bc',
    lighter: '#f8faf2',
  },
  white: '#ffffff',
  tints: {
    black10: 'rgba(0, 0, 0, 0.1)',
    black16: 'rgba(0, 0, 0, 0.16)',
  },
  transparent: 'transparent',
}

export const Shadows = {
  light: `0px 1px 6px ${Colors.tints.black10}`,
  normal: `0px 1px 2px ${Colors.tints.black16}`,
}
