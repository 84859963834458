import { Button, theme } from '@maersktankersdigital/web-components'
import { Box, Link, Slide, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { localStorageItemKeys } from '../../constants/local-storage-items'

export function CookieBanner() {
  const [cookieAccepted, setCookieAccepted] = useState(true)

  useEffect(() => {
    if (localStorage.getItem(localStorageItemKeys.cookieAccepted) !== 'true') {
      // setting timeout to delay the animation
      setTimeout(() => {
        setCookieAccepted(false)
      }, 500)
    }
  }, [])

  return (
    <Slide direction="up" in={!cookieAccepted}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          zIndex: 1201,
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          justifyContent: 'center',
          alignItems: { xs: 'center', xl: 'baseline' },
          background: theme.COLORS.greys.ultraDark,
          px: { xs: 3, sm: 5, md: 4 },
          py: 4,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: theme.COLORS.primary.white,
            pr: 5,
            pb: { xs: 3, xl: 0 },
          }}
        >
          By using this site you agree to the use of cookies for analytics and
          personalized content.{' '}
          <Link component={RouterLink} to="/privacy-policy" underline="none">
            Cookie Policy
          </Link>
        </Typography>
        <Button
          onClick={() => {
            setCookieAccepted(true)
            localStorage.setItem(localStorageItemKeys.cookieAccepted, 'true')
          }}
        >
          Accept & close
        </Button>
      </Box>
    </Slide>
  )
}
