import { Box } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { theme, useBreakpoint } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { axisStyles } from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/exposure-table.styles'
import { useExposureFilters } from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'

import { TableTooltip } from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/table-tool-tip'
import { useTotalExposureCount } from '~pages/pages-behind-login/position-list/hooks/use-total-exposure-count'
import { CustomTickProps } from '~pages/pages-behind-login/position-list/types/exposure-types'

export const StyledRegionTick = styled.text`
  font-family: ${theme.FONTS.families.regular};
  font-size: ${theme.FONTS.sizes.xxs};
  letter-spacing: 0.7px;
`

// Remove the parentheses from the region name
export const removeParenthesis = (string?: string) =>
  string?.replace(/\(.*?\)/g, '')

export const CustomRegionTick = ({ payload, ...rest }: CustomTickProps) => {
  const parsedValue = removeParenthesis(payload?.value)

  return (
    <g>
      {Array.isArray(parsedValue) ? (
        parsedValue.map((line, index) => (
          <StyledRegionTick
            {...rest}
            ref={null}
            fill={theme.COLORS.secondary.darkBlue.primary}
            data-cy="region-tick"
            dy={index === 0 ? '0em' : '1.2em'}
            x={rest.x}
            y={rest.y}
            key={index}
          >
            {line}
          </StyledRegionTick>
        ))
      ) : (
        <StyledRegionTick
          {...rest}
          ref={null}
          fill={theme.COLORS.secondary.darkBlue.primary}
          data-cy="region-tick"
        >
          {parsedValue}
        </StyledRegionTick>
      )}
    </g>
  )
}

export function ExposureGraph() {
  const { filters, showBothCargoGroups } = useExposureFilters()
  const { isMobileSize } = useBreakpoint()
  const totalExposure = useTotalExposureCount()

  const getDataKey = (group?: 'clean' | 'dirty') => {
    if (showBothCargoGroups) {
      const key = group === 'clean' ? 'cleanWithUnknown' : group
      if (filters?.showPercentage) {
        return `vesselPercentage.${key}`
      }
      return `vesselCount.${key}`
    }
    return filters?.showPercentage
      ? `vesselPercentage.${group}`
      : `vesselCount.${group}`
  }

  return (
    <Box
      sx={{
        pb: { xs: 3, xxxl: 20 },
      }}
    >
      <ResponsiveContainer
        width="100%"
        height="100%"
        aspect={isMobileSize ? 2 : 3}
      >
        <BarChart
          data={totalExposure}
          barGap={0}
          barCategoryGap={0.5}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke={theme.COLORS.greys.mid}
          />
          <XAxis
            dataKey="exposureArea"
            axisLine={false}
            tickLine={false}
            tick={<CustomRegionTick />}
            tickMargin={21}
            height={40}
            interval={0}
          />
          <YAxis
            style={axisStyles}
            axisLine={false}
            allowDecimals={false}
            tickLine={false}
            width={30}
            unit="%"
          />
          <Tooltip
            content={<TableTooltip />}
            cursor={{
              fill: theme.COLORS.primary.maerskBlue,
              fillOpacity: '10%',
            }}
          />
          <Bar
            dataKey={getDataKey('clean')}
            fill={theme.COLORS.primary.maerskBlue}
            name="Maersk Tankers Clean"
            barSize={8}
            stackId={1}
          >
            {totalExposure?.map((entry, index) => {
              const radius = entry?.vesselCount?.dirty
                ? [0, 0, 7.5, 7.5] //Flat top
                : [7.5, 7.5, 7.5, 7.5] //Round top

              //@ts-ignore
              return <Cell key={`cell-${index}`} radius={radius} />
            })}
          </Bar>
          <Bar
            dataKey={getDataKey('dirty')}
            fill={theme.COLORS.greys.dark}
            name="Maersk Tankers Dirty"
            barSize={8}
            stackId={1}
          >
            {totalExposure?.map((entry, index) => {
              const radius = entry?.vesselCount?.cleanWithUnknown
                ? [7.5, 7.5, 0, 0] //Flat bottom
                : [7.5, 7.5, 7.5, 7.5] //Round bottom

              // @ts-ignore
              return <Cell key={`cell-${index}`} radius={radius} />
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
