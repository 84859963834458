import { ThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { CookieBannerV2 } from '~components/cookie-banner/cookie-banner-v2'
import AnimatedAlertProvider from '~components/molecules/animated-alert/animated-alert-provider'
import { PageUnavailable } from '~components/page-unavailable'
import './index.css'
import { worker } from './mocks/browser'
import { routeWrapper as routes } from './routes/routes'
import InsiteDigitalTheme from './theme/index'

if (import.meta.hot) {
  import.meta.hot.accept()
}

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://99ce3e56658c4205b529ac4aedd8aeea@o4505107599851520.ingest.sentry.io/4505107605815297',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: 'production',
  })
}

if (import.meta.env.DEV) {
  worker.start({
    onUnhandledRequest: 'bypass',
  })
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const tagManagerArgs = {
  gtmId: 'GTM-MM44XLX', // defined in https://maersktankers.atlassian.net/browse/PPW-3065
}

TagManager.initialize(tagManagerArgs)

const router = createBrowserRouter(routes)

createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={InsiteDigitalTheme}>
    <Sentry.ErrorBoundary fallback={<PageUnavailable />}>
      <QueryClientProvider client={queryClient}>
        <AnimatedAlertProvider>
          <CookieBannerV2 />
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </AnimatedAlertProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </ThemeProvider>,
)
