import { theme } from '@maersktankersdigital/web-components'
import { LabelProps } from 'recharts'
import { CartesianViewBox } from 'recharts/types/util/types'

export const WeekLabel = ({ viewBox, ...rest }: LabelProps) => {
  const typedViewBox = viewBox as CartesianViewBox

  return (
    <text {...rest} style={{ fill: theme.COLORS.greys.dark }}>
      <tspan
        x={typedViewBox?.x}
        y={typedViewBox?.y}
        dy={27}
        dx={-13}
        textAnchor="start"
      >
        WEEK
      </tspan>
      <tspan
        x={typedViewBox?.x}
        y={typedViewBox?.y}
        dy={38}
        dx={-13}
        textAnchor="start"
      >
        NUMBER
      </tspan>
    </text>
  )
}
