import {
  AddIcon,
  Button,
  CheckIcon,
  CloseIcon,
  FileIcon,
  TextButton,
  theme,
  Typography,
} from '@maersktankersdigital/web-components'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { IVesselFile } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Link } from '~components/atoms/link'
import { Group } from '~components/molecules/group/group'
import { FileBoxText, StyledDropzone } from './file-box.styles'

type FileBoxStatuses =
  | 'empty'
  | 'pending_upload'
  | 'uploaded'
  | 'approve_or_reject'
  | 'approved'
  | 'pending_rejection'
  | 'rejected'
  | 'empty_vetting_document'

export interface IFileBox {
  canApprove?: boolean
  canEdit?: boolean
  file?: IVesselFile
  isApprovalRequired?: boolean
  isDirectUpload?: boolean
  isLoading?: boolean
  name: string
  onApprove?: () => void
  onChange?: (file?: File) => void
  onDownload?: (file?: IVesselFile) => void
  onReject?: () => void
  onRemove?: (file?: IVesselFile) => void
  onUpload?: (file?: File) => void
  status?: FileBoxStatuses
}

export const FileBox: FC<IFileBox> = ({
  isApprovalRequired = true,
  onUpload,
  onRemove,
  file,
  onDownload,
  name,
  onChange,
  onApprove,
  onReject,
  isLoading,
  status,
  canEdit = false,
  isDirectUpload = true,
}) => {
  const [uploadFile, setUploadFile] = useState<File>()
  const [uploadStatus, setUploadStatus] = useState<FileBoxStatuses>(
    status ?? 'empty',
  )

  const onDrop = useCallback(
    (files: File[]) => {
      setUploadFile(files[0])
      setUploadStatus('pending_upload')

      if (onChange) {
        onChange(files[0])
      }
    },
    [onChange],
  )

  const handleRemove = (event: MouseEvent) => {
    event.stopPropagation()
    setUploadFile(undefined)
    setUploadStatus(status === 'empty' ? 'empty' : 'empty_vetting_document')
    onRemove?.(file)
  }

  const handleApprove = (event: MouseEvent) => {
    event.stopPropagation()
    onApprove?.()
  }

  const handleReject = (event: MouseEvent) => {
    event.stopPropagation()
    onReject?.()
  }

  const handleUpload = (event: MouseEvent) => {
    event.stopPropagation()
    onUpload?.(uploadFile)
  }

  const handleDownload = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onDownload?.(file)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <StyledDropzone {...getRootProps()} isDragActive={isDragActive}>
      <Box ph={4}>
        {(uploadStatus === 'empty' || 'empty_vetting_document') && (
          // @ts-ignore
          <input id="file" name={name} style={{}} {...getInputProps()} />
        )}

        {/* 1. Choose a file */}
        {uploadStatus === 'empty' && (
          <Link>
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              gap={8}
            >
              <AddIcon size={20} />
              <FileBoxText variant="label">
                Click here or drag and drop to add a file
              </FileBoxText>
              <Group
                spacing={4}
                flex={0}
                flexDirection="row"
                alignItems="center"
              />
            </Box>
          </Link>
        )}

        {uploadStatus === 'empty_vetting_document' && (
          <Box alignItems="center" flexDirection="row">
            <AddIcon size={20} />

            <Box pl={1}>
              <FileBoxText variant="label">
                Click here or drag and drop to add a file
              </FileBoxText>
            </Box>
          </Box>
        )}

        {/* 2. Has picked a file */}
        {uploadStatus === 'pending_upload' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              mr={4}
            >
              <Box flexDirection="row" alignItems="center" flex={0}>
                <FileIcon size={46} />
                <FileBoxText variant="menu">
                  {truncateName(uploadFile?.name ?? '')}
                </FileBoxText>
              </Box>
              <Box flexDirection="row" alignItems="center" ml={2} flex={0}>
                <TextButton
                  icon={<CloseIcon size={20} />}
                  onClick={handleRemove}
                  type="button"
                >
                  Remove
                </TextButton>
              </Box>
            </Box>
            {isDirectUpload && (
              <Button
                isLoading={isLoading}
                onClick={handleUpload}
                type="button"
              >
                Upload
              </Button>
            )}
          </Box>
        )}

        {/* 3. Upload complete */}
        {uploadStatus === 'uploaded' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flexDirection="row" flex={0}>
                <Link onClick={handleDownload}>
                  <Typography variant="menu">
                    {`Upload of ${truncateName(file?.filename || name || '')} complete.`}
                  </Typography>
                </Link>
              </Box>

              {canEdit && (
                <Box flexDirection="row" alignItems="center" flex={0}>
                  <TextButton
                    icon={<CloseIcon size={20} />}
                    onClick={handleRemove}
                    type="button"
                  >
                    Remove
                  </TextButton>
                </Box>
              )}
            </Box>
            {!isApprovalRequired && (
              <Box ml={2} flex={0}>
                <Typography variant="menu">Ready for approval</Typography>
              </Box>
            )}
          </Box>
        )}

        {/* 4. Reject or approve */}
        {status === 'approve_or_reject' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Group spacing={1} flex={0} flexDirection="row" alignItems="center">
              <TextButton
                icon={<FileIcon size={46} />}
                onClick={handleDownload}
                type="button"
              >
                {truncateName(file?.filename ?? '')}
              </TextButton>
            </Group>
            <Group spacing={4} flex={0} flexDirection="row" alignItems="center">
              <Box flexDirection="row" alignItems="center">
                <TextButton
                  icon={<CloseIcon size={20} />}
                  onClick={handleRemove}
                  type="button"
                >
                  Remove
                </TextButton>
              </Box>
              <Button onClick={handleReject} variant="warning">
                Reject
              </Button>
              {file?.status !== 'approved' && (
                <Button
                  isLoading={isLoading}
                  onClick={handleApprove}
                  variant="secondary"
                  type="button"
                >
                  Approve
                </Button>
              )}
            </Group>
          </Box>
        )}

        {/* 5. Approved */}
        {status === 'approved' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Group spacing={1} flex={0} flexDirection="row" alignItems="center">
              <TextButton
                icon={<FileIcon size={46} />}
                onClick={handleDownload}
                type="button"
              >
                {truncateName(file?.filename ?? '')}
              </TextButton>
            </Group>
            <Group spacing={1} flex={0} flexDirection="row" alignItems="center">
              <CheckIcon size={20} />
              <FileBoxText
                variant="label"
                color={theme.COLORS.citrus.dark.primary}
              >
                Approved
              </FileBoxText>
            </Group>
          </Box>
        )}

        {/* 6. Submit rejection reason */}
        {status === 'pending_rejection' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <FileBoxText variant="menu" color={theme.COLORS.red.mid.primary}>
              Rejection reason not yet implemented
            </FileBoxText>
          </Box>
        )}

        {/* 7. File is rejected */}
        {status === 'rejected' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <FileBoxText variant="menu" color={theme.COLORS.red.mid.primary}>
              Rejected not yet implemented
            </FileBoxText>
          </Box>
        )}
      </Box>
    </StyledDropzone>
  )
}

function truncateName(name: string): string {
  if (name.length <= 15) {
    return name
  }
  return name.slice(0, 15) + '...' + name.slice(name.length - 4)
}
