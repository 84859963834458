import { theme } from '@maersktankersdigital/web-components'
import { FC } from 'react'

import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { Link } from '~components/atoms/link'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { Modal } from '~components/molecules/modal/legacy-modal'
import { DashboardModal } from '~components/organisms/modals/dashboard/dashboard-modal'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { CopyDashboardToClipboardTooltipContent } from '../tooltip-content/copy-dashboard-to-clipboard-tooltip-content'

export const CopyDashboardToClipboardButton: FC = () => (
  <Box flexDirection="row" justifyContent="flex-end">
    <Modal
      unsetSize
      trigger={
        <Link noUnderline>
          <Box mr={0.3}>
            <Icon name="copy" size={24} fill={Colors.blues.mid} />
          </Box>
          <Paragraph color={theme.COLORS.secondary.midBlue}>
            Copy to clipboard
          </Paragraph>
        </Link>
      }
      content={
        <DashboardModal
          content={<CopyDashboardToClipboardTooltipContent />}
          header="select what to copy"
        />
      }
    />
  </Box>
)
