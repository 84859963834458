import { ApiRoutes } from '~api'
import { PopupFormConfig } from '~components/molecules/popup/popup-form.types'
import { FormConfig } from '~components/organisms/form/form.types'
import { FORM_API_DATA_KEY } from '~components/organisms/form/form.utils'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export enum TrackFieldValue {
  EARNINGS_SIMULATION = 'earnings-simulation',
  NORMAL = 'normal',
  COMMERCIAL_MANAGEMENT = 'commercial-management',
  ONBOARDING = 'onboarding',
}

export enum VESSELS_PAGE_FORM_ID {
  SLIDE_FORM_0 = 'slide-form-0',
  SLIDE_FORM_1 = 'slide-form-1',
}

export const VESSELS_PAGE_POPUP_CONFIG: Record<string, PopupFormConfig> = {
  [VESSELS_PAGE_FORM_ID.SLIDE_FORM_0]: {
    fields: [
      {
        type: 'autoComplete',
        label: 'Imo / Vessel name *',
        id: 'imoNumber',
        maxLength: 20,
        listColors: [Colors.greys.dark],
        isRequired: true,
        apiConfig: {
          key: FORM_API_DATA_KEY.Q88_VESSELS,
          route: ApiRoutes.Q88.list,
          optionKeys: ['IMONumber', 'VesselName'],
        },
      },
      {
        type: 'input',
        label: 'New vessel name (optional)',
        id: 'vesselName',
      },
      {
        type: 'checkbox',
        headerLabel: 'Commercial Management',
        label: 'Yes',
        id: 'commercialManagement',
        customCheckValFn: (isChecked: boolean) =>
          isChecked
            ? TrackFieldValue.COMMERCIAL_MANAGEMENT
            : TrackFieldValue.NORMAL,
      },
      {
        type: 'select',
        label: 'Pool Partner',
        placeholder: 'Select pool partner...',
        id: 'poolPartner',
        isRequired: true,
        apiConfig: {
          key: FORM_API_DATA_KEY.POOL_PARTNERS,
          route: ApiRoutes.POOL_PARTNERS.LIST,
          optionKey: 'poolPartnerKey',
          optionLabel: 'name',
        },
      },
      {
        type: 'select',
        label: 'Pool',
        placeholder: 'Select pool...',
        id: 'pool',
        name: 'pool',
        isRequired: true,
        customDataFn: () => [
          { label: 'City', value: 'City' },
          { label: 'Flexi', value: 'Flexi' },
          { label: 'Intermediate', value: 'Intermediate' },
          { label: 'Handy', value: 'Handy' },
          { label: 'MR', value: 'MR' },
          { label: 'LR1', value: 'LR1' },
          { label: 'LR2', value: 'LR2' },
          { label: 'Aframax', value: 'Aframax' },
        ],
      },
      {
        type: 'checkbox',
        label: 'Yes',
        headerLabel: 'Earnings Simulation',
        id: 'track',
        customCheckValFn: (isChecked: boolean) =>
          isChecked
            ? TrackFieldValue.EARNINGS_SIMULATION
            : TrackFieldValue.NORMAL,
      },
    ].map((field) => ({ ...field, showError: true })) as FormConfig[],
  },
  [VESSELS_PAGE_FORM_ID.SLIDE_FORM_1]: {
    fields: [
      {
        type: 'datepicker',
        label: 'Target Date',
        id: 'targetDate',
      },
      {
        type: 'select',
        label: 'Voyage Manager',
        placeholder: 'Select voyage manager...',
        id: 'voyageManager',
        apiConfig: {
          key: FORM_API_DATA_KEY.VOYAGE_MANAGERS,
          route: ApiRoutes.Vessels.managers,
          optionKey: 'name',
        },
      },
      {
        type: 'select',
        label: 'MT PIC',
        placeholder: 'Select MT PIC...',
        id: 'partnerManager',
        apiConfig: {
          key: FORM_API_DATA_KEY.PARTNER_MANAGERS,
          route: ApiRoutes.Users.Managers.partnerManager,
          optionKey: 'name',
        },
      },
    ].map((field) => ({ ...field, showError: true })) as FormConfig[],
  },
}
