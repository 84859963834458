/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  Decimal: { input: any; output: any }
}

export type FilterSettings = {
  __typename?: 'FilterSettings'
  cargoGroup?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  exposureArea?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  pool?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  showPercentage?: Maybe<Scalars['Boolean']['output']>
  totalExposure?: Maybe<Scalars['Boolean']['output']>
  vesselSortField?: Maybe<SortableColumn>
  vesselSortOrder?: Maybe<SortOrder>
}

export type FilterSettingsInput = {
  cargoGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  exposureArea?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  pool?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  showPercentage?: InputMaybe<Scalars['Boolean']['input']>
  vesselSortField?: InputMaybe<SortableColumn>
  vesselSortOrder?: InputMaybe<SortOrder>
}

export type MtOpenVessel = {
  __typename?: 'MTOpenVessel'
  CargoGrade: Scalars['String']['output']
  CreatedAt_UTC: Scalars['DateTime']['output']
  ExposureWorldArea: Scalars['String']['output']
  FromDate_UTC: Scalars['DateTime']['output']
  IMO: Scalars['String']['output']
  LoadingAreaETA_UTC?: Maybe<Scalars['DateTime']['output']>
  LocalLoadingAreaETA?: Maybe<Scalars['DateTime']['output']>
  LocalOpenDate: Scalars['DateTime']['output']
  MaerskTankersArea3: Scalars['String']['output']
  OpenDate_UTC: Scalars['DateTime']['output']
  OpenPort: Scalars['String']['output']
  Pool: Scalars['String']['output']
  ToDate_UTC: Scalars['DateTime']['output']
  VesselId: Scalars['String']['output']
  VesselName: Scalars['String']['output']
}

export type MtOpenVesselsResponse = {
  __typename?: 'MTOpenVesselsResponse'
  latestCreatedDate: Scalars['DateTime']['output']
  openVessels: Array<OpenMtVessels>
  totalCount: Scalars['Int']['output']
  totalExposure: Array<TotalExposure>
}

export type Mutation = {
  __typename?: 'Mutation'
  upsertUser?: Maybe<User>
}

export type MutationUpsertUserArgs = {
  userID: Scalars['String']['input']
  userSettings: UserSettingsInput
}

export type OpenMtVessels = {
  __typename?: 'OpenMTVessels'
  Maersk: Array<OpenVesselData>
  exposureArea: Scalars['String']['output']
  fromDate: Scalars['DateTime']['output']
}

export type OpenVesselData = {
  __typename?: 'OpenVesselData'
  ID: Scalars['String']['output']
  cargoGrade?: Maybe<Scalars['String']['output']>
  loadingAreaETA?: Maybe<Scalars['DateTime']['output']>
  localLoadingAreaETA?: Maybe<Scalars['DateTime']['output']>
  localOpenDate?: Maybe<Scalars['DateTime']['output']>
  openDate?: Maybe<Scalars['DateTime']['output']>
  openPort?: Maybe<Scalars['String']['output']>
  openRegion?: Maybe<Scalars['String']['output']>
  openWorldArea?: Maybe<Scalars['String']['output']>
  pool?: Maybe<Scalars['String']['output']>
  vesselId: Scalars['String']['output']
  vesselName?: Maybe<Scalars['String']['output']>
  weekNumber: Scalars['String']['output']
}

export type Query = {
  __typename?: 'Query'
  getMTOpenVessels: MtOpenVesselsResponse
  getUser?: Maybe<User>
}

export type QueryGetUserArgs = {
  userID: Scalars['String']['input']
}

export type SortBy = {
  column: SortableColumn
  order: SortOrder
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortableColumn {
  BreadthExtreme = 'BREADTH_EXTREME',
  CommercialOperator = 'COMMERCIAL_OPERATOR',
  Deadweight = 'DEADWEIGHT',
  LengthOverall = 'LENGTH_OVERALL',
  LiquidCapacity = 'LIQUID_CAPACITY',
  OpenDate = 'OPEN_DATE',
  VesselName = 'VESSEL_NAME',
}

export type TotalExposure = {
  __typename?: 'TotalExposure'
  exposureArea?: Maybe<Scalars['String']['output']>
  vessels: Array<OpenVesselData>
}

export type User = {
  __typename?: 'User'
  id: Scalars['String']['output']
  userID: Scalars['String']['output']
  userSettings?: Maybe<UserSettings>
}

export type UserSettings = {
  __typename?: 'UserSettings'
  filterSettings?: Maybe<FilterSettings>
  vesselColumns?: Maybe<Array<Scalars['String']['output']>>
}

export type UserSettingsInput = {
  filterSettings?: InputMaybe<FilterSettingsInput>
  vesselColumns?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpsertUserMutationVariables = Exact<{
  userID: Scalars['String']['input']
  userSettings: UserSettingsInput
}>

export type UpsertUserMutation = {
  __typename?: 'Mutation'
  upsertUser?: {
    __typename?: 'User'
    userID: string
    userSettings?: {
      __typename?: 'UserSettings'
      vesselColumns?: Array<string> | null
      filterSettings?: {
        __typename?: 'FilterSettings'
        exposureArea?: Array<string | null> | null
        pool?: Array<string | null> | null
        cargoGroup?: Array<string | null> | null
      } | null
    } | null
  } | null
}

export type GetMtOpenVesselsQueryVariables = Exact<{ [key: string]: never }>

export type GetMtOpenVesselsQuery = {
  __typename?: 'Query'
  getMTOpenVessels: {
    __typename?: 'MTOpenVesselsResponse'
    totalCount: number
    latestCreatedDate: any
    openVessels: Array<{
      __typename?: 'OpenMTVessels'
      exposureArea: string
      fromDate: any
      Maersk: Array<{
        __typename?: 'OpenVesselData'
        cargoGrade?: string | null
        ID: string
        loadingAreaETA?: any | null
        localLoadingAreaETA?: any | null
        localOpenDate?: any | null
        openDate?: any | null
        openPort?: string | null
        openRegion?: string | null
        openWorldArea?: string | null
        pool?: string | null
        vesselId: string
        vesselName?: string | null
      }>
    }>
    totalExposure: Array<{
      __typename?: 'TotalExposure'
      exposureArea?: string | null
      vessels: Array<{
        __typename?: 'OpenVesselData'
        cargoGrade?: string | null
        ID: string
        loadingAreaETA?: any | null
        localLoadingAreaETA?: any | null
        localOpenDate?: any | null
        openDate?: any | null
        openPort?: string | null
        openRegion?: string | null
        openWorldArea?: string | null
        pool?: string | null
        vesselId: string
        vesselName?: string | null
      }>
    }>
  }
}

export type GetUserQueryVariables = Exact<{
  userID: Scalars['String']['input']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  getUser?: {
    __typename?: 'User'
    userID: string
    userSettings?: {
      __typename?: 'UserSettings'
      vesselColumns?: Array<string> | null
      filterSettings?: {
        __typename?: 'FilterSettings'
        exposureArea?: Array<string | null> | null
        showPercentage?: boolean | null
        pool?: Array<string | null> | null
        cargoGroup?: Array<string | null> | null
        totalExposure?: boolean | null
      } | null
    } | null
  } | null
}

export const UpsertUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userSettings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userSettings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userSettings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exposureArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGroup' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vesselColumns' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertUserMutation, UpsertUserMutationVariables>
export const GetMtOpenVesselsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMTOpenVessels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMTOpenVessels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openVessels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exposureArea' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fromDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Maersk' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGrade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingAreaETA' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localLoadingAreaETA',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openPort' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openRegion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openWorldArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestCreatedDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalExposure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exposureArea' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vessels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGrade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingAreaETA' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localLoadingAreaETA',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openPort' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openRegion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openWorldArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMtOpenVesselsQuery,
  GetMtOpenVesselsQueryVariables
>
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exposureArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'showPercentage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGroup' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalExposure' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vesselColumns' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>
