import { IVesselManager } from '~api/vessels/read'

export enum VesselStatus {
  INCOMING = 'incoming',
  ARCHIVED = 'archived',
  ONBOARDED = 'onboarded',
  ONBOARDING = 'onboarding',
}

interface ISelect {
  label: string
  value: string
}

export const pool: ISelect[] = [
  { label: 'City', value: 'City' },
  { label: 'Flexi', value: 'Flexi' },
  { label: 'Intermediate', value: 'Intermediate' },
  { label: 'Handy', value: 'Handy' },
  { label: 'MR', value: 'MR' },
  { label: 'LR1', value: 'LR1' },
  { label: 'LR2', value: 'LR2' },
  { label: 'Aframax', value: 'Aframax' },
]

export interface IVesselData {
  imoNumber?: string
  manager?: IVesselManager
  pool?: string
  poolPartner?: string
  status?: string
  targetDate?: string
  track?: string
  vesselName?: string
  voyageManager?: string
}
