import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

export interface MuiTooltipData {
  defaultProps?: ComponentsProps['MuiTab']
  styleOverrides?: ComponentsOverrides['MuiTab']
  variants?: ComponentsVariants['MuiTab']
}

export const MuiTab: MuiTooltipData = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontFamily: 'MaerskTextRegular',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
}
