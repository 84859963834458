import { localStorageItemKeys } from '~constants/local-storage-items'
import { Cookie } from '../cookie-banner-v2'

export function saveCookieSettings(allowedCookies: Set<Cookie>) {
  allowedCookies.add(Cookie['necessary'])
  localStorage.setItem(
    localStorageItemKeys.retainedPostLogout.allowedCookies,
    JSON.stringify(Array.from(allowedCookies)),
  )
}
