import { Button, theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import {
  ModalFooter,
  StyledInput,
} from '~components/molecules/modal/modal.styles'

export const StyledImoInput = styled(StyledInput)`
  && {
    color: ${theme.COLORS.citrus.dark.primary};
    pointer-events: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.SPACINGS[5]};
  width: 624px;
`

export const StyledArchiveButton = styled(Button)``

export const ModalSecondFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between;

  & > :only-child {
    margin: auto;
  }
`
