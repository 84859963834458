import {
  Button,
  Switch,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box, Divider, Typography } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { Cookie } from '../cookie-banner-v2'
import { saveCookieSettings } from '../utils/save-cookie-settings'
import { AcceptAllButton } from './accept-all-button'
import { CookieDetails } from './cookie-details'

export const StyledSecondaryButton = styled(Button)`
  max-width: 255px;
`

export function CookieSettings({
  onClickHandler,
  closeModal,
}: {
  onClickHandler: () => void
  closeModal: () => void
}) {
  const [allowedCookies, setAllowedCookies] = useState<Set<Cookie>>(new Set())

  function toggleCookie(cookie: Cookie) {
    allowedCookies.has(cookie)
      ? allowedCookies.delete(cookie)
      : allowedCookies.add(cookie)

    setAllowedCookies(new Set(allowedCookies))
  }

  return (
    <>
      <Typography>
        Some are used for statistical purposes and others are set up by third
        party services. By clicking ‘Accept all’, you accept the use of cookies.
      </Typography>
      <TextButton onClick={onClickHandler}>About cookies</TextButton>

      <Box sx={{ display: 'flex', gap: 4, my: 3 }}>
        <StyledSecondaryButton
          variant="secondary"
          onClick={() => {
            saveCookieSettings(allowedCookies)
            closeModal()
          }}
        >
          {allowedCookies.has(Cookie['statistical'])
            ? 'Update consent'
            : 'Use necessary cookies only'}
        </StyledSecondaryButton>
        <AcceptAllButton closeModal={closeModal} />
      </Box>

      <CookieDetails />

      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Switch
          name="Strictly necessary"
          label="Strictly necessary"
          checked
          disabled
        />
        <Switch
          name="Statistical"
          label="Statistical"
          onChange={() => toggleCookie(Cookie['statistical'])}
        />
      </Box>
    </>
  )
}
