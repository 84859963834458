export enum PortFunction {
  COMMENCING = 'Commencing',
  FUELING = 'Fueling',
  LOADING = 'Loading',
  WAITING = 'Waiting',
  DISCHARGING = 'Discharging',
  OTHER = 'Other',
  CANAL_TRANSIT = 'Canal Transit',
  REPAIR = 'Repair',
  CHARTERERS_ORDERS = "Charterer's Orders",
  TERMINATING = 'Terminating',
  DELIVERY = 'Delivery',
  FOR_ORDERS = 'For Orders',
  MULTI_PURPOSE = 'Multi-purpose',
  REDELIVERY = 'Redelivery',
  PASSING = 'Passing',
}

export const PORT_FUNCTION_LABEL: Record<PortFunction, string> = {
  [PortFunction.COMMENCING]: 'C',
  [PortFunction.DISCHARGING]: 'D',
  [PortFunction.FUELING]: 'F',
  [PortFunction.CHARTERERS_ORDERS]: 'CO',
  [PortFunction.CANAL_TRANSIT]: 'CT',
  [PortFunction.LOADING]: 'L',
  [PortFunction.MULTI_PURPOSE]: 'M',
  [PortFunction.OTHER]: 'O',
  [PortFunction.PASSING]: 'P',
  [PortFunction.REPAIR]: 'R',
  [PortFunction.TERMINATING]: 'T',
  [PortFunction.WAITING]: 'W',
  [PortFunction.FOR_ORDERS]: 'FO',
  [PortFunction.DELIVERY]: 'D',
  [PortFunction.REDELIVERY]: 'RD',
}

export enum VoyageStatus {
  SCHEDULED = 'Scheduled',
  CLOSED = 'Closed',
  COMPLETED = 'Completed',
  COMMENCED = 'Commenced',
}

export type VoyageItinerary = {
  arrDepStatus: 'AR' | 'SA' | 'TE'
  arrivalGmt?: string
  arrivalLocal?: string
  blCodes?: string[]
  cargoGrades?: string[]
  departureGmt?: string
  departureLocal?: string
  portFunction: PortFunction
  portName: string
  seq: number
  updatedAt?: string
}

export type Distance = {
  ETA: string
  distance_naut: number
  port_name: string
}

export type Voyage = {
  _id: string
  ballastPort: string
  blCodes: string
  cargoGrades: string
  cargoGroup: string
  chartererShortName: string
  commenceDate: string
  completeDate: string
  cpDate: string
  dischargePort: string
  distances: Distance[]
  fixtureDate: string
  itineraries: VoyageItinerary[]
  lastUpdateGmt?: string
  loadPort: string
  operator?: string
  productCodes: string
  status: VoyageStatus
  vesselCode: string
  voyageNumber: string
}
