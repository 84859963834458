import * as React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useStore } from 'src/context/store-provider'
import { useApi } from 'src/hooks/use-api'

import { ApiClient, ApiRoutes } from '~api/index'
import { IUserSettingsPositionListView } from '~api/user/read'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import {
  constantColumnsEnd,
  constantColumnsStart,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  CargoGroupEnum,
  PositionVesselData,
  VesselClassEnum,
  VesselSpecsEnum,
} from '~pages/pages-behind-login/position-list/types/position-list-types'

type PositionListViewContextType = {
  activeView?: IUserSettingsPositionListView
  clearFilters: () => void
  deleteView: (listName: string, listType: ListType) => Promise<void>
  isLoading: boolean
  persistActiveView: (listType: ListType, listName?: string) => Promise<void>
  persistFavoriteVessel: (IMO: string) => void
  saveSettings: (newSettings: SettingsState) => void
  saveView: (
    nextView?: IUserSettingsPositionListView,
    activeViewName?: string,
  ) => void
  settings?: SettingsState
}

type SettingsState = {
  activeViewName?: string
  changesPending?: boolean
  changesPendingSharedLists?: boolean
  exportedVessels?: string[]
  favoriteVessels?: string[]
  isExporting?: boolean
  myLists?: Record<string, IUserSettingsPositionListView>
  sharedLists?: Record<string, IUserSettingsPositionListView>
  showFavorites?: boolean
}

export type FilterTypes = {
  cargoGroup?: CargoGroupEnum[]
  pool?: VesselClassEnum[]
  showDuplicates?: boolean
  vessel?: string | null
  vesselSpecs?: VesselSpecsEnum[]
  worldArea?: string[]
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface ColumnSort {
  column: keyof PositionVesselData
  order: SortOrder
}

export type ListType = 'myLists' | 'sharedLists'

interface SharedLists {
  [key: string]: IUserSettingsPositionListView
}

export const initialColumnOrder = [
  ...constantColumnsStart,
  'openDate',
  'openPort',
  'voyages',
  'comments',
  ...constantColumnsEnd,
]

export const initialColumnSort = {
  column: 'openDate' as keyof PositionVesselData,
  order: SortOrder.ASC,
}

const PositionListViewContext = createContext<
  PositionListViewContextType | undefined
>(undefined)

const defaultFilters = {
  worldArea: ['ALL'],
  pool: [VesselClassEnum.ALL],
  cargoGroup: [CargoGroupEnum.ALL],
  vesselSpecs: [VesselSpecsEnum.ALL],
  vessel: null,
  showDuplicates: true,
}

const defaultView: IUserSettingsPositionListView = {
  name: 'default',
  columns: initialColumnOrder,
  sortColumn: initialColumnSort,
  showHeaders: false,
  filterSettings: defaultFilters,
}

const initialMyLists = {
  default: defaultView,
}

function PositionListViewProvider({ children }: React.PropsWithChildren) {
  const [isLoading, setLoading] = useState(false)
  const { setStatus } = useAnimatedAlert()
  const { data: sharedListsData } = useApi<SharedLists>(
    ApiRoutes.POSITION_LIST.SHARED_LISTS,
  )
  const [settings, setSettings] = useState<SettingsState>({
    myLists: initialMyLists,
    sharedLists: sharedListsData,
    activeViewName: initialMyLists.default.name,
    exportedVessels: [],
    favoriteVessels: [],
    changesPending: false,
    changesPendingSharedLists: false,
    showFavorites: false,
  })
  const [activeView, setActiveView] =
    useState<IUserSettingsPositionListView>(defaultView)

  useEffect(() => {
    if (!sharedListsData) return
    saveSettings({
      sharedLists: sharedListsData,
    })
  }, [sharedListsData])

  const store = useStore()
  const id = store.User.sub

  // Get views saved on user settings
  useEffect(() => {
    const getUser = (id: string) => {
      setLoading(true)
      // Promise based approach needed to make sure the settings are loaded before the isLoading boolean is changed
      ApiClient.User.read({ id })
        .then((userData) => {
          if (!userData.settings?.positionList) {
            return
          }
          setSettings((currentSettings) => ({
            ...currentSettings,
            ...userData?.settings?.positionList,
          }))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    !!id && getUser(id)
  }, [id])

  // set active view based on settings
  useEffect(() => {
    if (!settings.activeViewName) return
    let nextActiveView = settings.myLists?.[settings.activeViewName]

    if (!nextActiveView) {
      nextActiveView = settings.sharedLists?.[settings.activeViewName]
    }
    if (nextActiveView) {
      setActiveView(nextActiveView)
    }
  }, [settings.activeViewName])

  const clearFilters = () => {
    setActiveView((currentActiveView) => ({
      ...currentActiveView,
      filterSettings: defaultFilters,
    }))
    saveSettings({ changesPending: false })
  }

  const saveSettings = (newSettings: SettingsState) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }))
  }

  const deleteView = async (listName: string, listType: ListType) => {
    const nextLists = { ...settings[listType] }
    delete nextLists[listName]
    const isSharedList = listType === 'sharedLists'

    setLoading(true)

    if (isSharedList) {
      try {
        await ApiClient.PositionList.delete({ name: listName }).then(
          (response) => {
            if ('error' in response) {
              throw new Error()
            }
            setStatus('Shared list was successfully deleted', 5000, 'success')
            persistActiveViewName('default')
          },
        )
      } catch (error) {
        setStatus('Error deleting shared list', 5000, 'error')
        console.error('Error deleting shared list:', error)
        setLoading(false)
      }
    } else {
      try {
        await ApiClient.User.Settings.edit({
          positionList: {
            [listType]: nextLists,
            activeViewName: 'default',
          },
        })
        setStatus('Your list was successfully deleted', 5000, 'success')
      } catch (error) {
        setStatus('Error deleting list', 5000, 'error')
        console.error('Error deleting list:', error)
        setLoading(false)
      }
    }
    setLoading(false)
    saveSettings({
      [listType]: nextLists,
      activeViewName: 'default',
      changesPending: false,
      changesPendingSharedLists: false,
    })
  }

  const persistFavoriteVessel = async (IMO: string) => {
    const nextFavorites = settings?.favoriteVessels || []
    const isFavorite = nextFavorites.includes(IMO)
    const updatedFavorites = isFavorite
      ? nextFavorites.filter((vessel) => vessel !== IMO)
      : [...nextFavorites, IMO]
    saveSettings({ favoriteVessels: updatedFavorites })
    try {
      await ApiClient.User.Settings.edit({
        positionList: {
          favoriteVessels: updatedFavorites,
        },
      })
    } catch (error) {
      setStatus('Error saving favorite vessel', 5000, 'error')
      console.error('Error setting favorite vessel:', error)
    }
  }

  // save view to user settings
  const persistActiveView = async (listType: ListType, listName?: string) => {
    const saveListName = listName || (settings.activeViewName as string)
    const cleanActiveView = { ...activeView }
    delete cleanActiveView?.filterSettings?.vessel
    const nextLists = {
      ...settings[listType],
      [saveListName]: { ...cleanActiveView, name: saveListName },
    }
    const isSharedList = listType === 'sharedLists'
    saveSettings({
      [listType]: nextLists,
      activeViewName: saveListName,
    })

    setLoading(true)

    if (isSharedList) {
      await ApiClient.User.Settings.edit({
        positionList: {
          activeViewName: saveListName,
        },
      })

      const payload = { ...cleanActiveView, name: saveListName }
      ApiClient.PositionList.add(payload)
        .then((response) => {
          if ('error' in response) {
            throw new Error()
          }
          setActiveView(response as IUserSettingsPositionListView)
          setStatus('Shared list was successfully saved', 5000, 'success')
        })
        .catch((error: Error) => {
          setStatus(
            'Something went wrong when saving the shared list',
            5000,
            'error',
          )
          setLoading(false)
        })
    } else {
      try {
        await ApiClient.User.Settings.edit({
          positionList: {
            [listType]: nextLists,
            activeViewName: saveListName,
          },
        })
        if (settings.changesPending) {
          setStatus(
            'Your changes have been successfully saved',
            5000,
            'success',
          )
        } else {
          setStatus('You have successfully made a new list', 5000, 'success')
        }
      } catch (error) {
        setStatus('Error saving new list', 5000, 'error')
        console.error('Error persisting active view:', error)
        setLoading(false)
      }
    }
    setLoading(false)
    saveSettings({
      changesPending: false,
      changesPendingSharedLists: false,
    })
  }

  const persistActiveViewName = async (activeViewName: string) => {
    const cleanSettings = { ...settings }

    const keysToRemove = [
      'changesPending',
      'changesPendingSharedLists',
      'sharedLists',
    ]

    keysToRemove.forEach((key) => {
      delete cleanSettings[key as keyof SettingsState]
    })

    try {
      await ApiClient.User.Settings.edit({
        positionList: {
          ...cleanSettings,
          activeViewName,
        },
      })
    } catch (error) {
      console.error('Error persisting active view name:', error)
    }
  }

  const saveView = (
    nextView?: IUserSettingsPositionListView,
    activeViewName?: string,
  ) => {
    const isSharedList = !!activeView && !!activeView.createdAt
    if (nextView) {
      if ('showHeaders' in nextView) {
        setActiveView((currentView) => ({
          ...currentView,
          showHeaders: nextView.showHeaders,
        }))
      }

      if (nextView.filterSettings) {
        const nextFilterSettings = { ...activeView.filterSettings }

        for (const [name, value] of Object.entries(nextView.filterSettings)) {
          const valueArray = Array.isArray(value)
            ? value.map((val) => val.toString())
            : []
          let newFilterValue
          if (typeof value === 'string' || typeof value === 'boolean') {
            newFilterValue = value
          } else if (value) {
            const allIndex = valueArray.indexOf('ALL')
            if (value.length === 0) {
              newFilterValue = ['ALL']
            } else if (allIndex > -1 && allIndex === value.length - 1) {
              newFilterValue = ['ALL']
            } else {
              newFilterValue = value.filter((val) => val !== 'ALL')
            }
          }
          ;(nextFilterSettings as { [key: string]: any })[name] = newFilterValue
        }

        setActiveView((currentView) => ({
          ...currentView,
          filterSettings: nextFilterSettings,
        }))
      }

      if (nextView.columns) {
        setActiveView((currentView) => ({
          ...currentView,
          columns: nextView.columns,
        }))
      }

      if (nextView.sortColumn) {
        setActiveView((currentView) => ({
          ...currentView,
          sortColumn: nextView.sortColumn,
        }))
      }

      setSettings((currentSettings) => {
        // If searching for a vessel changes pending shouldn't be set to true
        if (
          nextView.filterSettings &&
          !nextView.filterSettings.hasOwnProperty('vessel')
        ) {
          return {
            ...currentSettings,
            changesPending: isSharedList ? false : true,
            changesPendingSharedLists: isSharedList ? true : false,
          }
        }
        return currentSettings
      })
    }

    // Active list name needs to be persisted to user settings
    if (activeViewName) {
      setSettings((currentSettings) => ({
        ...currentSettings,
        activeViewName,
      }))
      persistActiveViewName(activeViewName)
    }
  }

  const value = {
    activeView,
    saveSettings,
    clearFilters,
    deleteView,
    isLoading,
    persistActiveView,
    persistFavoriteVessel,
    saveView,
    settings,
  }

  return (
    <PositionListViewContext.Provider value={value}>
      {children}
    </PositionListViewContext.Provider>
  )
}

function usePositionListView() {
  const context = useContext(PositionListViewContext)
  if (context === undefined) {
    throw new Error(
      'useUserSettings must be used within a UserSettingsProvider',
    )
  }
  return context
}

export { PositionListViewProvider, usePositionListView }
