import { MultiSelect, TextButton } from '@maersktankersdigital/web-components'
import { ClickAwayListener } from '@mui/base'
import { useEffect } from 'react'

import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { AutoCompleteInputOptions } from '~components/molecules/auto-complete-input/auto-complete.types'
import AutoCompleteInput from '~components/molecules/auto-complete-input/auto-complete-input'
import { useAutoCompleteInputState } from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import { useFilteredData } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  cargoGroupOptions,
  vesselClassOptions,
  vesselSpecsOptions,
  worldAreaOptions,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-filter-bar/filter-options'
import {
  AutoCompleteWrapper,
  FavoritesTextButton,
  FilterControlsWrapper,
  FilterWrapper,
  LoadingPlaceholder,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-filter-bar/position-list-filter-bar.styles'
import AnimatedHeart from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/vessel-name-content/animated-heart'

export const PositionListFilterBar = () => {
  const { setStatus } = useAnimatedAlert()
  const {
    activeView,
    clearFilters,
    saveView,
    isLoading,
    saveSettings,
    settings,
  } = usePositionListView()
  const { filteredData } = useFilteredData()
  const { activeResult, setIsOpen } = useAutoCompleteInputState()
  const vesselMap = new Map()

  filteredData?.forEach((vessel) => {
    if (!vesselMap.has(vessel.vessel)) {
      vesselMap.set(vessel.vessel, {
        searchableItems: [{ value: vessel.vessel }],
      })
    }
  })

  const uniqueSearchableVessels: AutoCompleteInputOptions[] = Array.from(
    vesselMap.values(),
  )

  useEffect(() => {
    if (!activeResult) {
      saveView({
        filterSettings: { vessel: null },
      })
    }
    const vessel = activeResult?.[0].value
    saveView({
      filterSettings: { vessel },
    })
  }, [activeResult])

  const handleChangeFilters = (
    e: any,
    value: string[] | null,
    name?: string,
  ) => {
    if (!value || !name) return
    saveView({
      filterSettings: { [name]: value },
    })
  }

  const handleClearFilters = () => {
    clearFilters()
    setStatus('Filters cleared')
  }

  if (!activeView?.filterSettings) return null
  const filters = activeView.filterSettings

  return (
    <FilterWrapper>
      {!isLoading ? (
        <>
          <>
            <MultiSelect
              name="worldArea"
              options={worldAreaOptions}
              label="World Area"
              value={filters?.worldArea}
              onChange={handleChangeFilters}
            />

            <MultiSelect
              name="pool"
              options={vesselClassOptions}
              label="Pool"
              value={filters?.pool}
              onChange={handleChangeFilters}
            />
            <MultiSelect
              name="cargoGroup"
              options={cargoGroupOptions}
              label="Cargo grade"
              value={filters?.cargoGroup}
              onChange={handleChangeFilters}
            />
            <MultiSelect
              name="vesselSpecs"
              options={vesselSpecsOptions}
              label="Vessel Specs"
              value={filters?.vesselSpecs}
              onChange={handleChangeFilters}
            />
            <FilterControlsWrapper>
              <FavoritesTextButton
                variant="secondary"
                $filled={settings?.showFavorites || false}
                icon={
                  <AnimatedHeart filled={settings?.showFavorites || false} />
                }
                onClick={() =>
                  saveSettings({ showFavorites: !settings?.showFavorites })
                }
              >
                Show favorites
              </FavoritesTextButton>
              <TextButton
                onClick={handleClearFilters}
                variant="tertiary"
                data-cy="clear-filters"
              >
                Clear filters
              </TextButton>
            </FilterControlsWrapper>
          </>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <AutoCompleteWrapper>
              <AutoCompleteInput
                id="vesselName"
                placeholder="search vessel"
                options={uniqueSearchableVessels}
              />
            </AutoCompleteWrapper>
          </ClickAwayListener>
        </>
      ) : (
        <LoadingPlaceholder />
      )}
    </FilterWrapper>
  )
}
