import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { MenuItemI } from './primary'

const adminScopeRoutes: MenuItemI[] = [
  {
    label: 'admin',
    link: `${
      import.meta.env.PROD
        ? 'https://admin.insitedigital.net'
        : 'https://stage.admin.insitedigital.net'
    }`,
  },
  {
    label: 'open vps',
    link: 'http://performance.vpsolutions.dk',
  },
]

export function useSecondaryNavLinks(): MenuItemI[] {
  const isInternalUser = useHasScope(ExactScopeName.maerskTankersUser)

  if (isInternalUser) {
    return adminScopeRoutes
  }
  return []
}
