import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@maersktankersdigital/web-components'
import { removeLocalStorageItems } from '~utils/remove-local-storage-items'

const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <Button
      variant="primary"
      onClick={() => {
        removeLocalStorageItems()
        logout({ logoutParams: { returnTo: window.location.origin } })
      }}
    >
      Log Out
    </Button>
  )
}

export default LogoutButton
