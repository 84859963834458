import { theme } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

export interface MuiCheckboxData {
  defaultProps?: ComponentsProps['MuiCheckbox']
  styleOverrides?: ComponentsOverrides['MuiCheckbox']
  variants?: ComponentsVariants['MuiCheckbox']
}

const CheckedSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="26" height="26" rx="2" fill="#42B0D5" />
    <path
      d="M24.5573 9.69865L16.4216 22.065C16.1749 22.4399 15.7564 22.6655 15.3077 22.6655H13.3127C12.917 22.6655 12.5417 22.4898 12.2884 22.1858L7.48828 16.4257L8.51257 15.5721L13.3127 21.3322H15.3077L23.4435 8.96582L24.5573 9.69865Z"
      fill="white"
    />
  </svg>
)

export const MuiCheckbox: MuiCheckboxData = {
  defaultProps: {
    disableRipple: true,
    checkedIcon: <CheckedSVG />,
  },
  styleOverrides: {
    root: {
      color: 'transparent',
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='4' width='24' height='24' rx='1' fill='white' stroke='%23B3BDC5' stroke-width='2'/%3E%3C/svg%3E\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      '&.Mui-focusVisible': {
        color: theme.COLORS.secondary.lightBlue.primary,
        backgroundColor: theme.COLORS.secondary.lightBlue.tint,
      },

      '&:hover': {
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='4' width='24' height='24' rx='1' fill='white' stroke='%2357B9DA' stroke-width='2'/%3E%3Cpath d='M24.5573 9.69865L16.4216 22.065C16.1749 22.4399 15.7564 22.6655 15.3077 22.6655H13.3127C12.917 22.6655 12.5417 22.4898 12.2884 22.1858L7.48828 16.4257L8.51257 15.5721L13.3127 21.3322H15.3077L23.4435 8.96582L24.5573 9.69865Z' fill='%2357B9DA'/%3E%3C/svg%3E\")",
      },
    },
  },
}
