import { useQuery } from '@tanstack/react-query'
import { ApiConfig } from '~api/config'
import { IUserInfoReadResponse } from '~api/user/read'
import { tenMinutes } from '../../../constants/constants'
import { useFetch } from '../../../utils/fetch-request'

export function useGetMeVT() {
  const fetchRequest = useFetch<IUserInfoReadResponse>()
  return useQuery({
    queryKey: ['/me'],
    queryFn: () => fetchRequest(`${ApiConfig.domain}/api/me`),
    staleTime: tenMinutes,
  })
}
