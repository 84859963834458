import { ApiRoutes } from '~api'
import { apiUpdateAction } from '~api/actions'

import {
  IUserInfoReadResponse,
  IUserSettings,
  IUserSettingsPositionList,
} from '../read'

export type IUserSettingsEditResponse = IUserInfoReadResponse

export interface IUserSettingsEditPayload {
  positionList?: IUserSettingsPositionList
  settings?: IUserSettings
}

export function userSettingsEditAction(
  payload: IUserSettingsEditPayload,
): Promise<IUserSettingsEditResponse> {
  return apiUpdateAction<IUserSettingsEditPayload, IUserSettingsEditResponse>(
    ApiRoutes.User.Settings.update,
    payload,
  )
}
