import {
  Button,
  CloseIcon,
  ImportIcon,
  theme,
} from '@maersktankersdigital/web-components'
import { Icon } from '~components/atoms/icon'
import Heading from '~components/atoms/typography/heading/heading'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { CopyDashboardToClipboardButton } from '~components/organisms/dashboard/copy-dashboard-to-clipboard-button/copy-dashboard-to-clipboard-button'
import PoolPointsPageProvider from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page-provider'
import { PoolPointsResult } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-result/pool-points-result'
import { VesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { VesselStatus } from '~types/vessel.types'
import { getCopy } from '~utils//get-copy'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { PageRoute } from '../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { StyledVesselPageSubHeaderHeadingWrapper } from './vessel-page.styles'

export const VESSEL_PAGE_CONFIG = {
  [PageRoute.POOL_POINTS]: {
    PageProvider: PoolPointsPageProvider,
  },
}

export const VESSEL_PAGE_HEADER_DATA = {
  [PageRoute.VESSEL_DASHBOARD]: {
    buttonComponentFn: () => <CopyDashboardToClipboardButton />,
  },
  [PageRoute.OPS_DASHBOARD]: {
    buttonComponentFn: () => <CopyDashboardToClipboardButton />,
  },
  [PageRoute.DOCUMENTS]: {
    buttonComponentFn: (state: VesselPageState) => (
      <Button
        variant="secondary"
        icon={
          state.isBulkSelectActive ? (
            <CloseIcon size={16} />
          ) : (
            <ImportIcon size={16} />
          )
        }
        onClick={() => state.setIsBulkSelectActive(!state.isBulkSelectActive)}
      >
        <Paragraph>
          {state.isBulkSelectActive ? 'Cancel' : 'Select for download'}
        </Paragraph>
      </Button>
    ),
  },
  [PageRoute.QUESTIONNAIRE]: {
    buttonComponentFn: (state: VesselPageState) =>
      state.vessel?.status === VesselStatus.INCOMING && (
        <Button
          variant="secondary"
          onClick={() => state.setRerunEarningsSimulator(true)}
        >
          Run Earnings Simulator
        </Button>
      ),
  },
  [PageRoute.POOL_POINTS]: {
    buttonComponentFn: (state: VesselPageState) => (
      <Button
        variant="secondary"
        icon={<Icon name="copy" size={24} fill={Colors.blues.mid} />}
        onClick={() => {
          state.setExportToExcel(true)
        }}
      >
        <Paragraph color={theme.COLORS.secondary.midBlue}>
          Export data to Excel
        </Paragraph>
      </Button>
    ),
  },
}

type SubHeaderData = {
  additionalInfo?: {
    description?: {
      componentFn?: (state: VesselPageState) => React.ReactElement
      copy?: string
    }
    header?: {
      componentFn?: (state: VesselPageState) => JSX.Element
      copy?: string
    }
    links?: {
      copy: string
      url: string
    }[]
  }
  header?: {
    componentFn?: (state: VesselPageState) => JSX.Element
    copy?: string
  }
}

type VesselPageSubHeaderData = {
  [key in PageRoute]?: SubHeaderData
}

export const getVesselPageSubHeaderData = (
  state: VesselPageState,
  isAccountManager: boolean,
): VesselPageSubHeaderData => ({
  [PageRoute.BASIC_VESSEL_DATA]: {
    header: {
      copy: 'Basic Vessel Data',
    },
  },
  [PageRoute.VESSEL_DASHBOARD]: {},
  [PageRoute.OPS_DASHBOARD]: {},
  [PageRoute.DOCUMENTS]: {
    header: {
      copy: 'Documents',
    },
  },
  [PageRoute.POOL_RESTRICTIONS]: {
    header: {
      copy: 'Restrictions',
    },
  },
  [PageRoute.PORT_ESSENTIALS]: {
    header: {
      copy: 'Port Essentials',
    },
    additionalInfo: {
      description: {
        copy: 'The Port Essentials module is currently based solely on Q88 data. Always verify Owner’s pool points and trading restrictions, as well as the “Check before you fix” policy prior to fixing.',
      },
    },
  },
  [PageRoute.POOL_POINTS]: {
    header: {
      componentFn: () => {
        const today = new Date()
        const dateString = `${today.toLocaleString('default', {
          month: 'long',
        })} ${today.toLocaleString('default', { year: 'numeric' })}`

        return (
          <StyledVesselPageSubHeaderHeadingWrapper>
            <Heading data-cy="vessel-dashboard-subpage-header" type="h5">
              Pool Points
            </Heading>
            <Paragraph weight="bold">{dateString}</Paragraph>
          </StyledVesselPageSubHeaderHeadingWrapper>
        )
      },
    },
    additionalInfo: {
      header: {
        componentFn: (state: VesselPageState) => (
          <Heading type="h6">
            {getCopy('Pool Points for {$vesselName}', {
              vesselName: state.vessel?.vesselName || '',
            })}
          </Heading>
        ),
      },
      description: {
        componentFn: () => <PoolPointsResult />,
      },
      ...(isAccountManager && {
        links: [
          {
            copy: 'Go to Pool Point Questionnaire',
            url: state.poolPointsFlowUrl,
          },
        ],
      }),
    },
  },
  [PageRoute.QUESTIONNAIRE]: {
    header: {
      copy: 'Vessel Information',
    },
  },
  [PageRoute.VOYAGES]: {
    header: {
      copy: 'Voyages',
    },
  },
  [PageRoute.VETTING]: {
    header: {
      copy: 'Vetting',
    },
  },
})
