import { ChevronRightIcon } from '@maersktankersdigital/web-components'
import { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { IVesselsReadResponse } from '~api/vessels/read'
import { RouterLink } from '~components/atoms/router-link'
import Heading from '~components/atoms/typography/heading/heading'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import AnimatedAlertProvider from '~components/molecules/animated-alert/animated-alert-provider'
import AutoCompleteInput from '~components/molecules/auto-complete-input/auto-complete-input'
import AutoCompleteInputProvider, {
  useAutoCompleteInputState,
} from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import { NotificationsBanner } from '~components/notifications/notifications-banner'
import { EarningsSimulator } from '~components/organisms/modals/earnings-simulator/earnings-simulator'
import { IVesselRow } from '~components/organisms/tables/vessel/vessel-table'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useApi } from '~hooks/use-api'
import { VesselHeader } from '~pages/pages-behind-login/vessel-page/vessel-header/vessel-header'
import {
  getVesselPageSubHeaderData,
  VESSEL_PAGE_CONFIG,
  VESSEL_PAGE_HEADER_DATA,
} from '~pages/pages-behind-login/vessel-page/vessel-page-data'
import { VesselPageNav } from '~pages/pages-behind-login/vessel-page/vessel-page-nav/vessel-page-nav'
import VesselPageProvider, {
  useVesselPageState,
} from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import {
  StyledVesselPageSubHeader,
  StyledVesselPageSubHeaderAdditionalInfo,
  StyledVesselPageSubHeaderAdditionalInfoInner,
  StyledVesselPageSubHeaderAdditionalInfoLinks,
  StyledVesselPageSubHeaderInner,
  StyledVesselPageWrapper,
} from '~pages/pages-behind-login/vessel-page/vessel-page.styles'
import { StaticPageAttributes } from '~routing/constants/routes'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { getActiveRoute } from '~utils/get-active-route'
import { gtm } from '~utils/gtm'

type VesselPageContentProps = {
  data: IVesselsReadResponse
  pageConfigs: StaticPageAttributes | undefined
}

const getSearchableOptions = (vessels: IVesselRow[]) =>
  vessels.map((vessel) => ({
    searchableItems: [
      { value: vessel.vesselId, isHidden: true },
      { value: vessel.imoNumber, color: Colors.greys.dark },
      { value: vessel.vesselName },
    ],
  }))

export const VesselPageContent = ({
  data,
  pageConfigs,
}: VesselPageContentProps) => (
  <VesselPageProvider vessel={data || null}>
    <AutoCompleteInputProvider>
      <AnimatedAlertProvider>
        <VesselPageContentInner vessel={data} pageConfigs={pageConfigs} />
      </AnimatedAlertProvider>
    </AutoCompleteInputProvider>
  </VesselPageProvider>
)

type VesselPageContentInnerProps = {
  pageConfigs: StaticPageAttributes | undefined
  vessel: IVesselsReadResponse
}

const VesselPageContentInner = ({
  vessel,
  pageConfigs,
}: VesselPageContentInnerProps) => {
  const routeParams = useParams()
  const { data: vessels } = useApi<IVesselRow[]>(ApiRoutes.Vessels.all)
  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  const state = useVesselPageState()
  const autoCompleteState = useAutoCompleteInputState()

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [, activeRoute] = getActiveRoute(pathname, routeParams)

  const pageConfig = activeRoute && {
    // @ts-ignore
    header: VESSEL_PAGE_HEADER_DATA[activeRoute],
    subHeader: {
      header: getVesselPageSubHeaderData(state, !!isAccountManager)[activeRoute]
        ?.header,
      additionalInfo: getVesselPageSubHeaderData(state, !!isAccountManager)[
        activeRoute
      ]?.additionalInfo,
    },
    // @ts-ignore
    page: VESSEL_PAGE_CONFIG[activeRoute],
  }
  const PageProvider = pageConfig?.page?.PageProvider || Fragment

  useEffect(() => {
    const activeResult = autoCompleteState.activeResult

    if (!activeResult) return

    const resultVesselName = activeResult[2]?.value
    const resultVesselId = activeResult[0]?.value

    if (!resultVesselName && !resultVesselId) return

    gtm.pushEvent('internal_click', {
      click_type: 'vessel_searched',
      click_subtype: resultVesselName || resultVesselId,
    })

    const { pathname = '' } = window.location
    const { vesselId } = routeParams
    // Replacing the 24 vesselId characters with new resultVesselId.
    const newPathname = pathname.replace(
      new RegExp(`/${vesselId}/`, 'gm'),
      `/${resultVesselId}/`,
    )
    if (newPathname) {
      return navigate(newPathname, {
        replace: true,
      })
    }
  }, [autoCompleteState.activeResult])

  return (
    <PageProvider>
      <Helmet>
        <title>{`${vessel.vesselName} | VesselTruth`}</title>
      </Helmet>

      {pageConfigs && <VesselPageNav pageConfigs={pageConfigs} />}

      <StyledVesselPageWrapper>
        <VesselHeader pageConfig={pageConfig} vessel={vessel} />
        <NotificationsBanner />

        {pageConfig?.subHeader && (
          <StyledVesselPageSubHeader>
            <StyledVesselPageSubHeaderInner>
              {vessels && (
                <AutoCompleteInput
                  id="vesselName"
                  label="Switch to another vessel"
                  placeholder="Search for a specific Vessel"
                  options={getSearchableOptions(vessels)}
                />
              )}

              {pageConfig.subHeader.header?.copy ? (
                <Heading data-cy="vessel-dashboard-subpage-header" type="h5">
                  {`${pageConfig.subHeader.header.copy} for ${
                    vessel?.vesselName || 'Unknown'
                  }`}
                </Heading>
              ) : (
                pageConfig.subHeader.header?.componentFn?.(state)
              )}
            </StyledVesselPageSubHeaderInner>

            {pageConfig.subHeader?.additionalInfo && (
              <StyledVesselPageSubHeaderAdditionalInfo>
                <StyledVesselPageSubHeaderAdditionalInfoInner>
                  {pageConfig.subHeader.additionalInfo.header?.copy ? (
                    <Heading type="h6">
                      {pageConfig.subHeader.additionalInfo.header.copy}
                    </Heading>
                  ) : (
                    pageConfig.subHeader.additionalInfo.header?.componentFn?.(
                      state,
                    )
                  )}

                  {pageConfig.subHeader.additionalInfo.description?.copy ? (
                    <Paragraph size="medium">
                      {pageConfig.subHeader.additionalInfo.description.copy}
                    </Paragraph>
                  ) : (
                    pageConfig.subHeader.additionalInfo.description?.componentFn?.(
                      state,
                    )
                  )}
                </StyledVesselPageSubHeaderAdditionalInfoInner>

                <StyledVesselPageSubHeaderAdditionalInfoLinks>
                  {pageConfig.subHeader.additionalInfo.links?.map(
                    (config: Record<string, string>) => (
                      <RouterLink
                        to={config.url}
                        target={config.isExternal ? '_blank' : undefined}
                        key={config.copy}
                      >
                        <Paragraph>{config.copy}</Paragraph>
                        <ChevronRightIcon />
                      </RouterLink>
                    ),
                  )}
                </StyledVesselPageSubHeaderAdditionalInfoLinks>
              </StyledVesselPageSubHeaderAdditionalInfo>
            )}
          </StyledVesselPageSubHeader>
        )}

        <Outlet />
      </StyledVesselPageWrapper>

      <EarningsSimulator />
      <AnimatedAlert />
    </PageProvider>
  )
}
