import { theme } from '@maersktankersdigital/web-components'

import { getLastThreeVoyages } from '~components/organisms/dashboard/dashboard.utils'
import { AreaFilteredVessels } from '~pages/pages-behind-login/position-list/hooks/use-area-filtered-vessels'
import {
  CheckboxValues,
  MultiSelectValues,
} from '~pages/pages-behind-login/position-list/position-list-page/modals/export-modal/export-modal'
import {
  formatDate,
  getCellContent,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { vesselSpecsOptions } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  Consumption,
  PositionVesselData,
  UsCoc,
  WorldArea,
} from '~pages/pages-behind-login/position-list/types/position-list-types'
import { Voyage } from '~types/itinerary.types'

const contentParser: Record<
  keyof PositionVesselData | string,
  (cellContent: unknown, vessel: PositionVesselData) => string
> = {
  lastThreeCargos: (cellContent, vessel) => {
    return vessel?.lastThreeCargoesInternal?.lastThreeCargoes
      ? vessel.lastThreeCargoesInternal.lastThreeCargoes
      : getLastThreeVoyages(vessel?.voyages)
          ?.map((voyage: Voyage, i: number) => {
            return voyage.productCodes && voyage.productCodes
          })
          .join(' /') || ''
  },
  openDate: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM') : ''
  },
  openPort: (cellContent) => {
    return cellContent ? (cellContent as string) : ''
  },
  cbm: (cellContent) => {
    return cellContent ? Math.round(Number(cellContent)).toString() : ''
  },
  bunkerConsumption: (cellContent) => {
    const castContent = cellContent as Consumption
    return String(castContent?.ladenTonsPerDay || '')
  },
  imoClass: (cellContent, vessel) => {
    return cellContent && vessel.vesselStatus
      ? (cellContent as string).split(' ')?.[1]
      : (cellContent as string)
  },
  distances: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM') : ''
  },
  nextDryDock: (cellContent, vessel) => {
    const activeDryDock =
      vessel.nextDryDockInternal.nextDryDock || vessel.nextDryDock
    return activeDryDock ? formatDate(activeDryDock) : ''
  },
  lastUpdateGmt: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM HH:mm') : ''
  },
  usCoc: (cellContent) => {
    const { issued, lastAnnual } = cellContent as UsCoc
    return `${formatDate(issued)}<br>${formatDate(lastAnnual)}`
  },
  igs: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  igsSupply: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  tankCoating: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  dpp: (cellContent) => {
    return cellContent ? (cellContent as string) : 'N/A'
  },
}

const worldAreaMapping: WorldArea = {
  level1: 'Level 1',
  level2: 'Level 2',
  level3: 'Level 3',
}

const checkboxMapping = {
  openDate: 'Open Date',
  openPort: 'Open Port',
  lastThreeCargos: 'Last three cargos',
}

export function generateHTMLTable(
  vesselData: AreaFilteredVessels[],
  multiSelectValues: MultiSelectValues,
  checkboxValues: CheckboxValues,
  columns: string[],
  level = 0,
) {
  let htmlTable = ''

  const colSpanLength =
    multiSelectValues.vesselSpecs.length +
    multiSelectValues.eta.length +
    multiSelectValues.worldArea.length +
    Object.values(checkboxValues).filter((val) => val).length +
    1

  vesselData.forEach((item) => {
    // Filter vessels based on selectedVessels
    const selectedVessels = item?.vessels?.filter((vessel) =>
      multiSelectValues.selectedVessels.includes(vessel.vessel),
    )

    if (selectedVessels && selectedVessels.length > 0) {
      // Start the table
      htmlTable +=
        '<table style="border:1px solid black;border-collapse:collapse;">\n'

      // Add the area header
      if (item.name) {
        htmlTable += `<tr style="border:1px solid black;text-align:center;background-color:${
          level === 0
            ? theme.COLORS.secondary.darkBlue.primary
            : theme.COLORS.primary.maerskBlue
        }"><td colspan="${colSpanLength};" style="color:white;font-weight:bold;font-size:18px;">${
          item.name
        }</td></tr>\n`
      }

      // Add the column headers
      htmlTable += '<tr>\n'
      htmlTable +=
        '<th style="border:1px solid black;padding:0 8px;">Vessel Name</th>\n'

      multiSelectValues.vesselSpecs.forEach((spec) => {
        const matchingSpec = vesselSpecsOptions.find(
          (option) => option.value === spec,
        )
        htmlTable += `<th style="border:1px solid black;padding:0 8px;">${
          matchingSpec?.label || spec
        }</th>\n`
      })

      multiSelectValues.eta.forEach((port) => {
        htmlTable += `<th style="border:1px solid black;padding:0 8px;"><div>ETA ${port}</div></th>\n`
      })

      multiSelectValues.worldArea.forEach((area) => {
        htmlTable += `<th style="border:1px solid black;padding:0 8px;">${
          worldAreaMapping[area as keyof WorldArea]
        }</th>\n`
      })

      Object.entries(checkboxValues).forEach(([checkboxName, isChecked]) => {
        if (!isChecked) return
        htmlTable += `<th style="border:1px solid black;padding:0 8px;">
            ${checkboxMapping?.[checkboxName as keyof typeof checkboxMapping]}
          </th>`
      })

      htmlTable += '</tr>\n'
      htmlTable += '<tbody>\n'

      // Render vessel values
      selectedVessels.forEach((vessel) => {
        htmlTable += '<tr>\n'
        // Vessel name
        htmlTable += `<td style="border:1px solid black;padding:0 8px;">${vessel.vessel}</td>\n`

        // Vessel specs
        multiSelectValues.vesselSpecs.forEach((spec) => {
          const cellContent = getCellContent(vessel, spec)
          let renderedContent = ''
          if (contentParser[spec]) {
            renderedContent = contentParser[spec](cellContent, vessel)
          } else {
            renderedContent = cellContent ? (cellContent as string) : ''
          }
          htmlTable += `<td style="border:1px solid black;padding:0 8px;">${renderedContent}</td>\n`
        })

        // ETA
        {
          multiSelectValues.eta.forEach((port) => {
            const eta = vessel?.voyages?.[0]?.distances?.find(
              (item) => item.port_name === port,
            )?.ETA
            htmlTable += `
            <td style="border:1px solid black;padding:0 8px;">
              ${eta ? formatDate(eta) : ''}
            </td>
          `
          })
        }

        // World Area
        multiSelectValues.worldArea.forEach((area) => {
          const value = vessel?.worldArea?.[area as keyof WorldArea] || ''
          htmlTable += `
            <td style="border:1px solid black;padding:0 8px;">
              ${value}
            </td>
          `
        })

        // Checkboxes
        {
          Object.entries(checkboxValues).forEach(
            ([checkboxName, isChecked]) => {
              if (!isChecked) return
              const cellContent = getCellContent(vessel, checkboxName)
              let renderedContent = ''
              if (contentParser[checkboxName]) {
                renderedContent = contentParser[checkboxName](
                  cellContent,
                  vessel,
                )
              } else {
                renderedContent = isChecked ? (cellContent as string) : ''
              }
              htmlTable += `<td style="border:1px solid black;padding:0 8px;">${renderedContent}</td>\n`
            },
          )
        }

        htmlTable += '</tr>\n'
      })

      htmlTable += '</tbody>\n'
      htmlTable += '</table>\n'
    }

    // Recurse over the children
    if (item.children) {
      htmlTable += generateHTMLTable(
        item.children,
        multiSelectValues,
        checkboxValues,
        columns,
        level + 1,
      )
    }
  })

  return htmlTable
}
