import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'

import createContextStateHook from '~utils/create-hook-for-context-state'

export type FavoritesState = {
  addFavoriteVessel: (vesselId: string) => void
  favoriteVessels: string[]
  removeFavoriteVessel: (vesselId: string) => void
  setFavoriteVessels: Dispatch<SetStateAction<string[]>>
}

export const FavoritesContext = createContext<FavoritesState | null>(null)

export default function FavoritesProvider({ ...props }: PropsWithChildren) {
  const [favoriteVessels, setFavoriteVessels] = useState<string[]>([])

  const addFavoriteVessel = (vesselId: string) => {
    if (favoriteVessels.includes(vesselId)) return

    setFavoriteVessels([...favoriteVessels, vesselId])
  }

  const removeFavoriteVessel = (vesselId: string) => {
    setFavoriteVessels(
      favoriteVessels.filter((favorite) => favorite !== vesselId) ?? [],
    )
  }

  return (
    <FavoritesContext.Provider
      value={{
        favoriteVessels,
        setFavoriteVessels,
        addFavoriteVessel,
        removeFavoriteVessel,
      }}
      {...props}
    />
  )
}

export const useFavorites = createContextStateHook(
  FavoritesContext,
  'FavoritesContext',
)
