import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import LoadComponent from '~components/molecules/load-component/load-component'

export const AuthenticationGuard = ({ children }: { children: any }) => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } =
    useAuth0()
  const [isPassTimeout, setIsPassTimeout] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        setIsPassTimeout(true)
        return
      }
      try {
        const token = await getAccessTokenSilently()
        if (token) {
          setIsPassTimeout(true)
        }
      } catch (error: any) {
        // error.message === "Login required" is expected when user user tries to access a protected route with out being logged in
        // since this behavior is expected, we don't want to log this error nor report it to Sentry.io
        if (error?.message !== 'Login required') {
          console.error(error)
        }
      }
    })()

    // await getAccessTokenSilently for max 5 seconds
    setTimeout(() => {
      if (isPassTimeout) return
      setIsPassTimeout(true)
    }, 5000)
  }, [])

  if (isLoading || !isPassTimeout) {
    return <LoadComponent />
  }

  if (!isAuthenticated && isPassTimeout) {
    const PrivateRoute = () => children
    const Component = withAuthenticationRequired(PrivateRoute, {
      onRedirecting: () => <LoadComponent />,
    })

    return <Component />
  }

  return <>{children}</>
}
