const mockCity = [
  {
    type: 'forecast',
    trend: 'Decreasing',
    date: '2024-02-01T00:00:00',
    createdAt: '2024-02-14T14:10:31.3577025',
    fixedRatio: 0.93,
    fixedRate: 12194.0,
    unfixedRate: 11255.0,
    currentCalculatedRate: 12126.93,
    previousCalculatedRate: 12728.21,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-03-01T00:00:00',
    createdAt: '2024-02-14T14:10:38.7186641',
    fixedRatio: 0.0,
    fixedRate: 0.0,
    unfixedRate: 11755.0,
    currentCalculatedRate: 11755.0,
    previousCalculatedRate: 11725.0,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-04-01T00:00:00',
    createdAt: '2024-02-14T14:11:34.4801883',
    fixedRatio: 0.0,
    fixedRate: 0.0,
    unfixedRate: 12255.0,
    currentCalculatedRate: 12255.0,
    previousCalculatedRate: 12225.0,
    payoutRatio: 100.0,
  },
]

const mrMock = [
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-02-01T00:00:00',
    createdAt: '2024-02-22T10:02:31.1123974',
    fixedRatio: 0.95,
    fixedRate: 33696.0,
    unfixedRate: 33298.0,
    currentCalculatedRate: 33674.89,
    previousCalculatedRate: 31854.41,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-03-01T00:00:00',
    createdAt: '2024-02-22T10:02:53.3227659',
    fixedRatio: 0.25,
    fixedRate: 39358.0,
    unfixedRate: 30647.0,
    currentCalculatedRate: 32839.85,
    previousCalculatedRate: 31710.04,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-04-01T00:00:00',
    createdAt: '2024-02-22T10:07:48.27026',
    fixedRatio: 0.02,
    fixedRate: 29892.0,
    unfixedRate: 34083.0,
    currentCalculatedRate: 33991.05,
    previousCalculatedRate: 31986.88,
    payoutRatio: 100.0,
  },
]

const lr1Mock = [
  {
    type: 'forecast',
    trend: 'Decreasing',
    date: '2024-02-01T00:00:00',
    createdAt: '2024-02-22T10:02:30.9302091',
    fixedRatio: 0.9,
    fixedRate: 28731.0,
    unfixedRate: 28980.0,
    currentCalculatedRate: 28755.9,
    previousCalculatedRate: 29872.75,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Decreasing',
    date: '2024-03-01T00:00:00',
    createdAt: '2024-02-22T10:02:53.3028654',
    fixedRatio: 0.0,
    fixedRate: 0.0,
    unfixedRate: 38980.0,
    currentCalculatedRate: 38980.0,
    previousCalculatedRate: 47027.0,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Stable',
    date: '2024-04-01T00:00:00',
    createdAt: '2024-02-14T14:14:53.4790125',
    fixedRatio: 0.0,
    fixedRate: 0.0,
    unfixedRate: 39027.0,
    currentCalculatedRate: 39027.0,
    previousCalculatedRate: 39027.0,
    payoutRatio: 100.0,
  },
]

const handyMock = [
  {
    type: 'forecast',
    trend: 'Decreasing',
    date: '2024-02-01T00:00:00',
    createdAt: '2024-02-22T10:02:31.0254381',
    fixedRatio: 0.9,
    fixedRate: 27296.0,
    unfixedRate: 27445.0,
    currentCalculatedRate: 27311.63,
    previousCalculatedRate: 27797.4,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-03-01T00:00:00',
    createdAt: '2024-02-22T10:02:53.3084827',
    fixedRatio: 0.28,
    fixedRate: 27009.0,
    unfixedRate: 27259.0,
    currentCalculatedRate: 27188.48,
    previousCalculatedRate: 27134.78,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Increasing',
    date: '2024-04-01T00:00:00',
    createdAt: '2024-02-22T10:07:48.261947',
    fixedRatio: 0.09,
    fixedRate: 27417.0,
    unfixedRate: 26790.0,
    currentCalculatedRate: 26843.39,
    previousCalculatedRate: 26619.01,
    payoutRatio: 100.0,
  },
]

const lr2Mock = [
  {
    type: 'forecast',
    trend: 'Stable',
    date: '2024-02-01T00:00:00',
    createdAt: '2024-02-22T10:02:31.0680554',
    fixedRatio: 1.0,
    fixedRate: 34883.0,
    unfixedRate: 0.0,
    currentCalculatedRate: 34883.0,
    previousCalculatedRate: 34883.0,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Stable',
    date: '2024-03-01T00:00:00',
    createdAt: '2024-02-22T10:02:53.3411397',
    fixedRatio: 0.09,
    fixedRate: 34885.0,
    unfixedRate: 42854.0,
    currentCalculatedRate: 42106.91,
    previousCalculatedRate: 42106.91,
    payoutRatio: 100.0,
  },
  {
    type: 'forecast',
    trend: 'Stable',
    date: '2024-04-01T00:00:00',
    createdAt: '2024-02-22T10:07:48.1102862',
    fixedRatio: 0.0,
    fixedRate: 0.0,
    unfixedRate: 40854.0,
    currentCalculatedRate: 40854.0,
    previousCalculatedRate: 40854.0,
    payoutRatio: 100.0,
  },
]

export const mockForecast = lr2Mock
