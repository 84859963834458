export type VesselDashboardTab = {
  AssignedSDWT?: string
  BWTS?: string
  DPP?: string
  Deadweight?: VesselParameters
  EEXI?: {
    eplStatus: EexiEplStatus
    serviceSpeed: number | string
  }
  IMOClass?: string
  IceClassLevel?: string
  MultipleSDWT?: string
  NextDryDock?: VesselParameters | string
  Ownership_Operation?: {
    CommericalOperator?: {
      ContactDetail?: Q88ContactDetails
    }
    DisponentOwner?: {
      ContactDetail?: Q88ContactDetails
    }
    RegisteredOwner?: {
      ContactDetail?: Q88ContactDetails
    }
  }
  Scrubber?: string
  Steaming?: string
  TC?: string
  TotalCubic?: VesselParameters
  bankingDetails?: BankingDetails
  bunkerConsumption?: BunkerConsumption
  ciiRating?: CiiRating
  commercialOperator?: ContactDetails
  disponentOwner?: ContactDetails
  vesselId?: string
}

interface BunkerConsumption {
  ballastTonsPerDay: number
  ladenTonsPerDay: number
  updatedAt: Date
  ladenTonsPerDayPoolAvg: number
  poolAvgCalculatedAt: Date
  ladenTonsPerDayPoolRating: 'low' | 'medium' | 'high' | undefined
}

export type VesselParameters = {
  attr: { Measure: string }
  val: string
}

export enum EexiEplStatus {
  NOT_INSTALLED = 'Not Installed',
  INSTALLED = 'Installed',
  COMPLIANT = 'Compliant',
}

export type Q88ContactDetails = {
  Address1?: string
  Company?: string
  Email?: string
  FullStyle?: string
  Phone?: string
  Website?: string
}

type BankingDetails = {
  IBAN?: string
  SWIFT?: string
  accountNumber?: string
  address?: string
  company?: string
  currency?: string
  header?: string
}

type CiiRating = {
  'CII Rating'?: CII
  'CII Rating Previous'?: CII
  'Calendar Year'?: string
  IMONumber?: string
  'Vessel Key'?: string
}

export enum CII {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export type ContactDetails = {
  address?: string
  company?: string
  email?: string
  phone?: string
  website?: string
}
