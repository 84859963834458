import {
  DeleteIcon,
  TextArea,
  TextButton,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'

export const NextDryDockPopupContentWrapper = styled(PopupContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ActiveDryDock = styled.div<{ hasInternalDryDock?: boolean }>`
  ${typographyStyles.label}
  color: ${(p) =>
    p.hasInternalDryDock
      ? theme.COLORS.secondary.midBlue
      : theme.COLORS.secondary.darkBlue};
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  input {
    width: 344px;
  }

  .react-datepicker-wrapper {
    width: 344px;
  }
  .react-datepicker__month-container {
    padding: 16px 38px;
  }
`

export const StyledTextArea = styled(TextArea)`
  width: 344px;
  margin-bottom: 104px; // to make sure the entire calendar is visible
`

export const NextDateWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`

export const StyledTextButton = styled(TextButton)`
  color: ${theme.COLORS.red.dark.primary};
`

export const StyledDeleteIcon = styled(DeleteIcon)`
  & path {
    fill: ${theme.COLORS.red.dark.primary};
  }
`

export const UserDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  font-family: ${theme.FONTS.families.regular};
`

export const StyledEditButton = styled(TextButton)`
  margin-left: auto;
  padding: 0;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
`
