type FeatureToggle = {
  key: string
  value: boolean
}

enum Mode {
  Development = 'development',
  Production = 'production',
}

enum Subdomain {
  Test = 'test',
  Staging = 'stage',
}

const dateToggle = (date: string): boolean => {
  return new Date(date) < new Date()
}

const modeToggle = (mode: Mode): boolean => {
  return import.meta.env.MODE === mode
}

const subdomainToggle = (subdomain: Subdomain): boolean => {
  const host = window.location.host
  return host.includes(subdomain)
}

const setFeatureToggleStorage = (key: string, isEnabled: boolean): void => {
  localStorage.setItem(key, isEnabled.toString())
}

export const getFeatureToggle = (featureToggle: FeatureToggle): boolean => {
  if (featureToggle.value === true) {
    return true
  }

  const featureToggleStorage = localStorage.getItem(featureToggle.key)
  if (featureToggleStorage === 'true') {
    return true
  } else if (featureToggleStorage === 'false') {
    return false
  }

  if (import.meta.env.MODE !== 'production') {
    setFeatureToggleStorage(featureToggle.key, false)
  }
  return false
}

// Define feature toggles here

// Examples:
/*
  export const testFeature = {
    // this is used for the localstorage key
    key: 'testFeature',
    // this will be the default value and will be evaluated before the localstorage value. If this is false
    // then the localstorage will be evaluated.
    value: false,
  };
  
  export const testFeature2 = {
    // this is used for the localstorage key
    key: 'testFeature2',
    // this will always be shown in local and staging environments and will always be shown in all environments
    // after 2023-01-01.
    value: modeToggle(Mode.Staging) || dateToggle('2024-01-31'),
  };
  */

export const cookieBannerV2 = {
  key: 'cookie-banner-v2',
  value: false,
}
