import { apiDeleteAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

export interface ISharedListDeleteResponse {
  message?: string
  statusCode?: number
}

export interface ISharedListDeletePayload {
  name: string
}

export function sharedListDeleteAction(
  payload: ISharedListDeletePayload,
): Promise<ISharedListDeleteResponse> {
  return apiDeleteAction<ISharedListDeletePayload, ISharedListDeleteResponse>(
    ApiRoutes.POSITION_LIST.SHARED_LISTS,
    payload,
  )
}
