import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react'

import { IUserSettings } from '~api/user/read'
import createContextStateHook from '~utils/create-hook-for-context-state'

export type UserState = {
  hasVesselRole: (roleName: UserRoleName, entityId?: string) => boolean
  isAnyPoolPartnerUser: () => boolean
  isHighLevelUser: () => boolean
  isMTUser: () => boolean
  isSuperReader: () => boolean
  isSuperUser: () => boolean
  setInfoData: Dispatch<SetStateAction<UserInfoData | null>>
  setRoleData: Dispatch<SetStateAction<UserRoleData | null>>
} & UserRoleData &
  UserInfoData

export type UserRoleData = Partial<{
  poolpartnerRoles: Role[]
  roles: UserRole[]
  sub: string | null
  vesselRoles: Role[]
}>

export type UserInfoData = Partial<{
  department: string
  email: string
  name: string
  phoneNumber?: string
  secondaryPhoneNumber?: string
  settings?: IUserSettings
}>

export interface Role {
  role: string
  roleDisplayName: string
}

export enum UserRoleName {
  GENERAL = '-',
  ADMIN = 'admin',
  MT_USER = 'mtuser',
  MT_OPS = 'mtops',
  READER = 'reader',
  USER = 'user',
  CHARTERING = 'chartering',
  TECHNICAL = 'technical',
  VETTING = 'vetting',
  POOL_PARTNER_ADMIN = 'poolpartneradmin',
  POOL_PARTNER = 'poolpartner',
  ACCOUNT_MANAGER = 'accountmanager',
  FEATURE_FLAG = 'feature-flag',
}

export type UserRole = {
  entityId: string
  entityType: string
  name: UserRoleName
}

export const UserContext = createContext<UserState | null>(null)

export default function UserProvider({ ...props }: PropsWithChildren) {
  const [roleData, setRoleData] = useState<UserRoleData | null>(null)
  const [infoData, setInfoData] = useState<UserInfoData | null>(null)

  const { poolpartnerRoles, roles, sub, vesselRoles } = useMemo(
    () => ({
      poolpartnerRoles: roleData?.poolpartnerRoles,
      roles: roleData?.roles,
      sub: roleData?.sub,
      vesselRoles: roleData?.vesselRoles,
    }),
    [roleData],
  )
  const {
    email,
    name,
    phoneNumber,
    secondaryPhoneNumber,
    settings,
    department,
  } = useMemo(
    () => ({
      email: infoData?.email,
      name: infoData?.name,
      phoneNumber: infoData?.phoneNumber,
      secondaryPhoneNumber: infoData?.secondaryPhoneNumber,
      settings: infoData?.settings,
      department: infoData?.department,
    }),
    [infoData],
  )

  function hasEntityRole(role: Partial<UserRole>) {
    const { entityId, entityType } = role
    const roleToCheck: Partial<UserRole> = { entityType }

    if (entityId?.length) {
      roleToCheck.entityId = entityId
    }

    if (role?.name?.length) {
      roleToCheck.name = role.name
    }

    return hasRole(roleToCheck)
  }

  function hasVesselRole(roleName: UserRoleName, entityId?: string) {
    return hasEntityRole({
      entityType: 'vessel',
      name: roleName,
      entityId,
    })
  }

  function hasPoolPartnerRole(roleName: UserRoleName, entityId?: string) {
    return hasEntityRole({
      entityType: 'poolpartner',
      name: roleName,
      entityId,
    })
  }

  // Shortcut if it's a common `hasRole` check
  function isPartnerManagerUser() {
    return hasRole({
      entityType: '*',
      entityId: '*',
      name: UserRoleName.ACCOUNT_MANAGER,
    })
  }

  function isSuperUser() {
    return hasRole({
      entityType: '*',
      entityId: '*',
      name: UserRoleName.ADMIN,
    })
  }

  // All internal users with email ending on @maersktankers.com will have this role.
  function isMTUser() {
    return hasRole({
      entityType: '*',
      entityId: '*',
      name: UserRoleName.MT_USER,
    })
  }

  function isHighLevelUser() {
    return isSuperUser() || isPartnerManagerUser()
  }

  function isSuperReader() {
    return hasRole({
      entityType: '*',
      entityId: '*',
      name: UserRoleName.READER,
    })
  }

  function isAnyPoolPartnerUser() {
    return hasPoolPartnerRole(UserRoleName.USER, '-')
  }

  function hasRole(roleToFind: Partial<UserRole>) {
    return roleData?.roles
      ? roleData.roles.some(
          (role) => JSON.stringify(role) === JSON.stringify(roleToFind),
        )
      : false
  }

  return (
    <UserContext.Provider
      value={{
        poolpartnerRoles,
        roles,
        sub,
        vesselRoles,
        email,
        name,
        department,
        phoneNumber,
        secondaryPhoneNumber,
        settings,
        setRoleData,
        setInfoData,
        hasVesselRole,
        isAnyPoolPartnerUser,
        isSuperReader,
        isSuperUser,
        isHighLevelUser,
        isMTUser,
      }}
      {...props}
    />
  )
}

export const useUser = createContextStateHook(UserContext, 'UserContext')
