import { theme } from '@maersktankersdigital/web-components'
import { getWeek } from 'date-fns'
import styled from 'styled-components/macro'

import { StyledRegionTick } from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/region-tick'
import { CustomTickProps } from '~pages/pages-behind-login/position-list/types/exposure-types'

const StyledWeekTick = styled(StyledRegionTick)`
  color: ${theme.COLORS.greys.dark};
`

export const CustomWeekTick = ({ payload, ...rest }: CustomTickProps) => {
  const value = payload?.value ? getWeek(new Date(payload?.value)) : ''
  return (
    <StyledWeekTick
      {...rest}
      fill={theme.COLORS.greys.dark}
      ref={null}
      scale={undefined}
      data-cy="week-tick"
    >
      {value}
    </StyledWeekTick>
  )
}
