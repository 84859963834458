import { useAuth0 } from '@auth0/auth0-react'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'

const isLocalhost = window.location.hostname === 'localhost'

export const url = `${
  isLocalhost
    ? import.meta.env.VITE_API_URL ||
      'https://horizon-test.maersktankers.digital'
    : ''
}/api/graphql`

export function useGraphQLMutation<
  TResult,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: Omit<
    UseMutationOptions<TResult, unknown, TVariables>,
    'mutationFn'
  >,
): UseMutationResult<TResult, unknown, TVariables, unknown> {
  const { getAccessTokenSilently } = useAuth0()
  const mutationFn = async (variables: TVariables) => {
    const accessToken = await getAccessTokenSilently()

    const graphQLClient = new GraphQLClient(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    // @ts-ignore
    return graphQLClient.request(document, variables)
  }

  return useMutation({ mutationFn, ...options })
}
