import { graphql } from './generated'

export const upsertUserMutation = graphql(/* GraphQL */ `
  mutation upsertUser($userID: String!, $userSettings: UserSettingsInput!) {
    upsertUser(userID: $userID, userSettings: $userSettings) {
      userID
      userSettings {
        filterSettings {
          exposureArea
          pool
          cargoGroup
        }
        vesselColumns
      }
    }
  }
`)
