import { DatePicker } from '@maersktankersdigital/web-components'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SWRResponse } from 'swr'
import { IError } from '~api/types'
import AutoCompleteInput from '~components/molecules/auto-complete-input/auto-complete-input'
import { useAutoCompleteInputState } from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import {
  getAllTagFilters,
  getMainTableWrapperStatus,
  getTagFilteredTables,
} from '~components/organisms/table/main-table-wrapper/main-table-wrapper.utils'
import { TableConfig } from '~components/organisms/table/table.types'
import { TableStatus } from '~components/organisms/table/table-component-renderer'
import { tableDataTransformer } from '~components/organisms/table/table-data-transformer/table-data-transformer'
import {
  DataTransformerConfig,
  DataTransformerConfigSelectConfig,
  TableDataTransformerTable,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.types'

import { Box } from '@mui/material'
import { ITabTable } from '~hooks/queries/vessels/data/types/tabs-read'
import { useStore } from '../../../../context/store-provider'
import useOld from '../../../../hooks/use-old'
import { isChildrenRenderFunction } from '../../../../utils/type-utils/react'
import ErrorBoundary from '../../../layout/error/error-boundary/error-boundary'
import { TagFilter, TagFilters } from '../../../layout/tag-filter/tag-filter'
import {
  StyledMainTableWrapper,
  StyledMainTableWrapperFilterWrapper,
  StyledMainTableWrapperInner,
  StyledSelect,
} from './main-table-wrapper.styles'

type MainTableWrapperProps = {
  apiData: SWRResponse<any, IError>
  children: (params: {
    filteredTables: ITabTable[]
    status: TableStatus
    transformedTableConfig: [TableConfig, TableDataTransformerTable[]]
  }) => ReactNode
  config: DataTransformerConfig<any>
  state?: any
}

export const MainTableWrapper = ({
  config,
  apiData,
  state,
  children,
}: MainTableWrapperProps) => {
  const store = useStore()
  const { vesselId } = useParams()

  const [selectFilterFns, setSelectFilterFns] = useState<
    ((tableData: any[]) => any[])[]
  >([])
  const [transformedTableConfig, setTableConfig] = useState<
    [TableConfig, TableDataTransformerTable[]]
  >([{}, []])
  const [isEqualState, setIsEqualState] = useState(false)
  const [hasInitialized, setHasInitialized] = useState(false)

  // Create a new object from the existing state - MobX only listens to
  // object value updates, not the entire object itself
  const reactiveState: any = state
    ? Object.getOwnPropertyNames(state).reduce(
        (newState, key) => ({ ...newState, [key]: state[key] }),
        {},
      )
    : null

  const datepickerDate = state?.datepickerDate
  const isDataLoaded = store.isDataLoaded(apiData)

  const { tableConfig } = config
  const autoCompleteConfig = config.wrapperConfig!.autoCompleteConfig
  const tagFilterConfig = config.wrapperConfig!.tagFilterConfig
  const filterConfig = config.wrapperConfig!.filterConfig

  const autoCompleteInputState = useAutoCompleteInputState()
  const autoCompleteInputId = autoCompleteConfig ? autoCompleteConfig.id : null
  const autoCompleteResults = autoCompleteInputId
    ? autoCompleteInputState.results[autoCompleteInputId]
    : []

  const hasSearchInputValue = Boolean(
    autoCompleteInputState.inputValue &&
      autoCompleteInputState.inputValue.length > 0,
  )
  const hasAutoCompleteResults =
    autoCompleteResults && autoCompleteResults.length > 0

  const hasActiveFilters =
    store.tagFilters.length > 0 ||
    selectFilterFns.length > 0 ||
    hasSearchInputValue ||
    datepickerDate

  // @ts-ignore
  const allTagFilters: TagFilters[] = getAllTagFilters(
    tagFilterConfig,
    apiData,
    isDataLoaded,
  )

  const filteredTables = useMemo(() => {
    let tables = apiData.data

    // Tagfilter filtering
    if (store.tagFilters.length > 0) {
      tables = getTagFilteredTables(tagFilterConfig, apiData, store.tagFilters)
    }

    const datepickerConfig = filterConfig?.datepickerConfig
    const datepickerKeys: string[] =
      datepickerDate && Object.keys(datepickerDate)

    // Datepicker filtering
    if (
      datepickerConfig &&
      datepickerKeys.length > 0 &&
      datepickerConfig.length > 0
    ) {
      tables = datepickerKeys.reduce((tables, name) => {
        const filterFn = datepickerConfig.find(
          (obj) => obj.name === name,
        )?.filterFn

        if (!filterFn) return tables

        return filterFn(datepickerDate[name], [...tables], state)
      }, tables)
    }

    // Selectbox filtering
    if (selectFilterFns.length > 0) {
      // Sorts according to filterConfig
      tables = selectFilterFns.reduce(
        (tables, filterFn) =>
          tables.map((tables: any) => filterFn([...tables])),
        tables,
      )
    }

    // Autocomplete filtering
    if (autoCompleteConfig?.dataFilterer && hasAutoCompleteResults) {
      tables = autoCompleteConfig!.dataFilterer(tables, autoCompleteResults)
    }

    return tables
  }, [
    selectFilterFns,
    store.tagFilters,
    datepickerDate,
    autoCompleteResults,
    apiData,
  ])

  // Create options for autocomplete functionality based on the table data
  const autoCompleteOptions = useMemo(() => {
    if (!autoCompleteConfig?.dataTransformer) {
      return []
    }

    return autoCompleteConfig?.dataTransformer(filteredTables)
  }, [filteredTables])

  const hasFilteredTableResults =
    filteredTables.length > 0 && filteredTables.flat().length > 0

  const oldState = useOld(reactiveState)

  useEffect(() => {
    const isEqual = Boolean(state && oldState)
      ? Object.getOwnPropertyNames(state).every(
          (key) => state[key] === oldState[key],
        )
      : false

    setIsEqualState(isEqual)
  }, [reactiveState, oldState])

  useEffect(() => {
    if (!hasFilteredTableResults) {
      return
    }

    if (
      // Only re-render new table config when the state has changed
      state &&
      !isEqualState &&
      hasInitialized
    ) {
      return
    }

    const config = tableConfig.tableCellConfig(store, reactiveState, vesselId)

    tableDataTransformer(config, filteredTables).then((response) => {
      const [tableHeadConfig, tableDataConfig] = response

      setTableConfig([tableHeadConfig, tableDataConfig])
    })
  }, [filteredTables, isEqualState, hasInitialized])

  const [, tableDataConfig] = transformedTableConfig

  const status = useMemo(
    () =>
      getMainTableWrapperStatus(
        apiData,
        tableDataConfig,
        isDataLoaded,
        hasActiveFilters,
        hasFilteredTableResults,
      ),
    [
      apiData,
      store.tagFilters,
      hasActiveFilters,
      hasFilteredTableResults,
      tableDataConfig,
    ],
  )

  useEffect(() => {
    if (status !== TableStatus.DONE) return

    setHasInitialized(true)
  }, [status])

  const childComponent = isChildrenRenderFunction(children)
    ? children({
        status,
        filteredTables,
        transformedTableConfig,
      })
    : children

  return (
    <StyledMainTableWrapper>
      <StyledMainTableWrapperFilterWrapper>
        {allTagFilters.length > 0 && (
          <TagFilter filters={allTagFilters} variant="deprecated" />
        )}

        {filterConfig && (
          <>
            {filterConfig.selectConfig?.map(
              (
                config: DataTransformerConfigSelectConfig<any>,
                index: number,
              ) => (
                <StyledSelect
                  // eslint-disable-next-line react/no-array-index-key
                  key={`table-filter-multi-select-${index}`}
                  name={config.label.replaceAll(' ', '-')}
                  label={config.label}
                  icon={config.icon}
                  // @ts-ignore
                  options={config.optionConfig.map(({ label }) => ({
                    label,
                    value: label,
                  }))}
                  // disabling eslint because value is second argument
                  // eslint-disable-next-line
                  onChange={(e, value) =>
                    setSelectFilterFns(
                      config.optionConfig
                        .filter(({ label }) => value?.includes(label))
                        .map(({ filterFn }) => filterFn),
                    )
                  }
                />
              ),
            )}

            {filterConfig.datepickerConfig?.map((config, index) => (
              <DatePicker
                // eslint-disable-next-line react/no-array-index-key
                key={`datepicker-${index}`}
                name={config.name}
                label={config.label}
                selected={datepickerDate[config.name] || new Date()}
                onChange={(date) =>
                  state.setDatepickerDate({
                    ...datepickerDate,
                    [config.name]: date,
                  })
                }
                highlightDates={config.highlightDatesFn?.(apiData, state)}
              />
            ))}
          </>
        )}
      </StyledMainTableWrapperFilterWrapper>

      <ErrorBoundary>
        <StyledMainTableWrapperInner>
          {autoCompleteConfig ? (
            <>
              {(status === TableStatus.DONE ||
                status === TableStatus.NO_RESULTS ||
                status === TableStatus.PREPARING) && (
                <Box sx={{ mb: '16px', alignSelf: 'flex-end' }}>
                  <AutoCompleteInput
                    id={autoCompleteInputId!}
                    placeholder={autoCompleteConfig.placeholder}
                    options={autoCompleteOptions}
                    maxLength={autoCompleteConfig.maxLength}
                  />
                </Box>
              )}

              {childComponent}
            </>
          ) : (
            <>{childComponent}</>
          )}
        </StyledMainTableWrapperInner>
      </ErrorBoundary>
    </StyledMainTableWrapper>
  )
}
