export interface CustomTickProps extends React.SVGProps<SVGTextElement> {
  index?: number
  numBars?: number
  payload?: { value: string }
  visibleTicksCount?: number
}

export enum OpenAreaEnum {
  ALL = 'ALL',
  NWE = 'NWE (Amsterdam)',
  AG = 'AG (Fuj)',
  NORTH_ASIA = 'North Asia (Yosu)',
  SOUTH_ASIA = 'South Asia (Singapore)',
  MED = 'MED (Malta)',
  AMERICAS = 'Americas (Houston)',
}

export enum VesselPoolEnum {
  ALL = 'ALL',
  HANDY = 'Handy',
  MR = 'MR',
  LR1 = 'LR1',
  LR2 = 'LR2',
  INTERMEDIATE = 'Intermediate',
  CITY = 'City',
}

export enum CargoTypeEnum {
  ALL = 'ALL',
  CLEAN = 'CPP',
  DIRTY = 'DPP',
}
