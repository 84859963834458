import { useQuery } from '@tanstack/react-query'
import { IUsersResponse } from '~api/users/all'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetUsersVT() {
  const fetchRequest = useFetch<IUsersResponse[]>()
  return useQuery({
    queryKey: ['/users'],
    queryFn: () => fetchRequest(`${apiBase()}/users`),
    staleTime: tenMinutes,
  })
}
