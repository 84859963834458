import {
  Alert,
  Checkbox,
  InfoCircleIcon,
  Switch,
} from '@maersktankersdigital/web-components'
import { keepPreviousData } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { getMTOpenVessels } from '~api/gql/queries'
import { useGraphQL } from '~hooks/use-graphql'
import ExposureFilterBar from '~pages/pages-behind-login/position-list/exposure-page/exposure-filter-bar/exposure-filter-bar'
import ExposureList from '~pages/pages-behind-login/position-list/exposure-page/exposure-list/exposure-list'
import ExposureTable from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/exposure-table'

import {
  FormControls,
  StyledTitle,
  StyledWrapper,
  TitleWrapper,
  TopWrapper,
} from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/exposure-table.styles'
import ExposureToolTip from '~pages/pages-behind-login/position-list/exposure-page/exposure-tool-tip'
import {
  Divider,
  NavButtonDivider,
  NavButtonsWrapper,
} from '~pages/pages-behind-login/position-list/position-list.styles'
import NavButton from '~pages/pages-behind-login/position-list/position-list-page/nav-button'
import PositionListHeader from '~pages/pages-behind-login/position-list/position-list-page/position-list-header'
import {
  ExposureFilterProvider,
  FilterTypes,
  OpenVessels,
  useExposureFilters,
} from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'
import { UserSettingsProvider } from '~pages/pages-behind-login/position-list/contexts/user-settings-provider'
import { ModalWrapper } from '~pages/pages-behind-login/user-page/user-page.styles'
import { formatDate } from '~utils/dates'
import { gtm } from '~utils/gtm'
import { usePageViewTracking } from '../tracking/hooks/use-page-view-tracking'
import LoadComponent from '~components/molecules/load-component/load-component'

function ExposurePage() {
  const {
    data: openVesselsData,
    isLoading,
    isError,
  } = useGraphQL(getMTOpenVessels, {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    placeholderData: keepPreviousData,
  })

  if (isLoading) {
    return <LoadComponent />
  }

  if (isError) {
    return (
      <ModalWrapper>
        <Alert
          variant="error"
          text="Oops there was an error. Please reload the page or try again later."
        />
      </ModalWrapper>
    )
  }

  return (
    <UserSettingsProvider>
      <ExposureFilterProvider data={openVesselsData?.getMTOpenVessels}>
        {openVesselsData && (
          <Exposure
            latestCreatedDate={
              openVesselsData.getMTOpenVessels.latestCreatedDate
            }
          />
        )}
      </ExposureFilterProvider>
    </UserSettingsProvider>
  )
}

interface Props {
  latestCreatedDate: string
}

function Exposure({ latestCreatedDate }: Props) {
  const { filters, saveFilters } = useExposureFilters()
  const [activeSelection, setActiveSelection] = useState<OpenVessels>()
  usePageViewTracking()

  const lastUpdateDateFormatted = formatDate(
    latestCreatedDate || '',
    'dd/MM/yyyy - HH:mm',
  )

  useEffect(() => {
    setActiveSelection(undefined)
  }, [filters?.totalExposure])

  function handleChartClick(data: CategoricalChartState) {
    if (!data.activePayload) {
      return
    }

    gtm.pushEvent('internal_click', {
      click_type: 'Exposure in selected week',
      click_subtype: data.activePayload?.[0].payload.exposureArea,
    })

    const vessels = data.activePayload?.[0].payload
    setActiveSelection(vessels)
  }

  const handleFilterChange = (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    newValue: string[] | null,
    name?: string,
  ) => {
    if (!name || !newValue) return
    setActiveSelection(undefined)

    gtm.pushEvent('internal_click', {
      click_type: 'filter_exposure',
      click_subtype: name,
    })
    saveFilters(name as keyof FilterTypes, newValue)
  }

  return (
    <>
      <PositionListHeader />
      <StyledWrapper>
        <ExposureFilterBar onFilterChange={handleFilterChange} />
        <Divider />
        <NavButtonsWrapper>
          <NavButton end to="/position-list">
            List
          </NavButton>
          <NavButtonDivider />
          <NavButton end to="/position-list/exposure">
            Exposure
          </NavButton>
        </NavButtonsWrapper>
        <TopWrapper>
          <TitleWrapper>
            <StyledTitle>Open Vessels</StyledTitle>
            <ExposureToolTip
              content={`Vessel exposure for the current week and the next three weeks, sorted by week and region. The current week includes vessels that opened in previous weeks and are still open. Data is updated hourly; the last update was on: ${lastUpdateDateFormatted}.
            `}
            >
              <InfoCircleIcon />
            </ExposureToolTip>
          </TitleWrapper>
          <FormControls>
            <Switch
              name="totalExposure"
              label="Show Total Exposure"
              checked={Boolean(filters?.totalExposure)}
              onChange={(e) => {
                saveFilters('weeks', [])
                saveFilters('totalExposure', e.target.checked)
              }}
            />
            <Checkbox
              label="show in percentage"
              name="showPercentage"
              checked={Boolean(filters?.showPercentage)}
              onChange={(e) => saveFilters('showPercentage', e.target.checked)}
              data-cy="percentage"
            />
          </FormControls>
        </TopWrapper>
        <ExposureTable onChartClick={handleChartClick} />
        <ExposureList activeSelection={activeSelection} />
      </StyledWrapper>
    </>
  )
}

export default ExposurePage
