import { theme } from '@maersktankersdigital/web-components'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'

interface NavButtonProps {
  $isSelected: boolean
}

interface Props extends NavLinkProps {
  children: string
}

const BottomBorder = styled.span<NavButtonProps>`
  position: absolute;
  background: ${(p) =>
    p.$isSelected
      ? theme.COLORS.secondary.barBlue
      : theme.COLORS.secondary.darkBlue.primary};
  width: ${(p) => (p.$isSelected ? '85%' : 0)};
  transition: width 0.3s;
  height: 2px;
  bottom: -2px;
`

const StyledNavLink = styled(NavLink)``

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.SPACINGS[0]};

  &:hover {
    ${BottomBorder} {
      width: 85%;
      transition: width 0.3s;
    }
  }

  & ${StyledNavLink}:focus-visible {
    outline: 2px solid ${theme.COLORS.primary.maerskBlue};
  }
`

const Button = styled.button<NavButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(p) =>
    p.$isSelected
      ? theme.COLORS.secondary.barBlue
      : theme.COLORS.secondary.darkBlue.primary};
  font-family: ${theme.FONTS.families.headlines.light};
  position: relative;
  font-size: ${theme.FONTS.sizes.xl};
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

function NavButton({ children, to, end }: Props) {
  return (
    <Wrapper tabIndex={-1}>
      <StyledNavLink tabIndex={0} end={end} to={to}>
        {({ isActive }) => (
          <Button $isSelected={isActive} tabIndex={-1}>
            {children}
            <BottomBorder $isSelected={isActive} />
          </Button>
        )}
      </StyledNavLink>
    </Wrapper>
  )
}

NavButton.displayName = 'NavButton'

export default NavButton
