import { useAuth0 } from '@auth0/auth0-react'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import {
  useQuery,
  UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'

const isLocalhost = window.location.hostname === 'localhost'

export const url = `${
  isLocalhost ? import.meta.env.VITE_API_URL : ''
}/api/graphql`

export function useGraphQL<
  TResult,
  TVariables extends Record<string, unknown> = Record<string, never>,
>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: Omit<
    UseQueryOptions<TResult, unknown, TResult>,
    'queryFn' | 'queryKey'
  >,
  variables?: TVariables,
): UseQueryResult<TResult, unknown> {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery({
    queryKey: [(document.definitions[0] as any).name.value, variables],
    queryFn: async () => {
      const token = await getAccessTokenSilently()

      const graphQLClient = new GraphQLClient(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      // @ts-ignore
      return graphQLClient.request(document, variables)
    },
    ...options,
  })
}
