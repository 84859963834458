import { apiPostAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { IUserSettingsPositionListView } from '~api/user/read'

export interface ISharedListUpdateErrorResponse {
  statusCode?: number
}

export function sharedListAddAction(
  payload: IUserSettingsPositionListView,
): Promise<IUserSettingsPositionListView | ISharedListUpdateErrorResponse> {
  return apiPostAction<
    IUserSettingsPositionListView,
    IUserSettingsPositionListView | ISharedListUpdateErrorResponse
  >(ApiRoutes.POSITION_LIST.SHARED_LISTS, payload)
}
