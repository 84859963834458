import { graphql } from './generated'

export const getMTOpenVessels = graphql(/* GraphQL */ `
  query getMTOpenVessels {
    getMTOpenVessels {
      openVessels {
        exposureArea
        fromDate
        Maersk {
          cargoGrade
          ID
          loadingAreaETA
          localLoadingAreaETA
          localOpenDate
          openDate
          openPort
          openRegion
          openWorldArea
          pool
          vesselId
          vesselName
        }
      }
      totalCount
      latestCreatedDate
      totalExposure {
        exposureArea
        vessels {
          cargoGrade
          ID
          loadingAreaETA
          localLoadingAreaETA
          localOpenDate
          openDate
          openPort
          openRegion
          openWorldArea
          pool
          vesselId
          vesselName
        }
      }
    }
  }
`)

export const getUserQuery = graphql(/* GraphQL */ `
  query getUser($userID: String!) {
    getUser(userID: $userID) {
      userID
      userSettings {
        filterSettings {
          exposureArea
          showPercentage
          pool
          cargoGroup
          totalExposure
        }
        vesselColumns
      }
    }
  }
`)
