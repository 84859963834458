import { UseQueryResult } from '@tanstack/react-query'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SWRResponse } from 'swr'
import { IError } from '~api/types'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import LoadComponent from '~components/molecules/load-component/load-component'
import { TableConfig } from '~components/organisms/table/table.types'
import { tableDataTransformer } from '~components/organisms/table/table-data-transformer/table-data-transformer'
import {
  DataTransformerConfig,
  DataTransformerConfigTableConfig,
  TableDataTransformerTable,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.types'
import { useStore } from '~context/store-provider'
import { ITabTable } from '~hooks/queries/vessels/data/types/tabs-read'
import { StyledTableStatus } from './table.styles'

export enum TableStatus {
  PREPARING = 'preparing',
  DONE = 'done',
  ERROR = 'error',
  NO_RESULTS = 'no-results',
  NO_DATA = 'no-data',
}

export const TABLE_STATUS_STRING = {
  [TableStatus.PREPARING]: 'Preparing tables...',
  [TableStatus.ERROR]: 'An error has occurred.',
  [TableStatus.NO_RESULTS]:
    "We couldn't find any results matching your search.",
  [TableStatus.NO_DATA]: "There's no data available for this request.",
  [TableStatus.DONE]: '',
}

type TableComponentRendererProps = {
  apiData: SWRResponse<any, IError> | UseQueryResult<any, Error> | any
  config: DataTransformerConfig<any>
  state?: any
}

export const TableComponentRenderer = ({
  config,
  apiData,
  state,
}: TableComponentRendererProps) => {
  const wrapperConfig = config.wrapperConfig
  const { tableConfig } = config

  const customApiData = tableConfig.dataSourceConfig?.dataFn?.(apiData)
  const tableApiData = customApiData
    ? { ...apiData, data: customApiData }
    : apiData

  const WrapperComponent = wrapperConfig ? wrapperConfig.Component : Fragment

  return (
    // @ts-ignore
    <WrapperComponent
      {...(wrapperConfig ? { config, state, apiData: tableApiData } : {})}
    >
      {/* @ts-ignore */}
      {wrapperConfig ? (
        ({ ...props }) => (
          <TableComponent
            apiData={tableApiData}
            tableConfig={tableConfig}
            state={state}
            {...props}
          />
        )
      ) : (
        <TableComponent
          apiData={tableApiData}
          tableConfig={tableConfig}
          state={state}
        />
      )}
    </WrapperComponent>
  )
}

type TableComponentProps = {
  apiData: SWRResponse<any, IError> | UseQueryResult<any, Error> | any
  filteredTables?: ITabTable[]
  state?: any
  status?: TableStatus
  tableConfig: DataTransformerConfigTableConfig<any>
  transformedTableConfig?: [TableConfig, TableDataTransformerTable[]]
}

const TableComponent = ({
  status,
  tableConfig,
  transformedTableConfig,
  apiData,
  filteredTables,
  state,
}: TableComponentProps) => {
  const store = useStore()
  const { vesselId } = useParams()

  const tableApiData: any = apiData.data ?? []
  const isTabsDataLoaded = store.isDataLoaded(apiData)
  const tables = filteredTables ?? tableApiData

  const [[tableHeadConfig, tableDataConfig], setTableConfig] = useState<
    [TableConfig, TableDataTransformerTable[]]
  >([{}, []])

  useEffect(() => {
    if (transformedTableConfig && transformedTableConfig.length > 0) {
      setTableConfig(transformedTableConfig)

      return
    }

    if (tables.length === 0) {
      return
    }

    const config = tableConfig.tableCellConfig(store, state, vesselId)

    tableDataTransformer(config, tables).then((response) => {
      const [tableHeadConfig, tableDataConfig] = response

      setTableConfig([tableHeadConfig, tableDataConfig])
    })
  }, [tables, transformedTableConfig, state])

  const tableStatus = useMemo(() => {
    if (status) return status

    if (apiData.error) {
      return TableStatus.ERROR
    }

    if (isTabsDataLoaded && tableApiData && tableApiData.length === 0) {
      return TableStatus.NO_DATA
    }

    if (tableApiData.length === 0) {
      return TableStatus.NO_RESULTS
    }

    if (tableDataConfig.length > 0) {
      return TableStatus.DONE
    }

    return TableStatus.PREPARING
  }, [apiData, status])

  const TableComponent = tableConfig.Component

  return (
    <>
      {TABLE_STATUS_STRING[tableStatus] && (
        <StyledTableStatus>
          <Paragraph>{TABLE_STATUS_STRING[tableStatus]}</Paragraph>

          {status === TableStatus.PREPARING && <LoadComponent />}
        </StyledTableStatus>
      )}

      {tableStatus === TableStatus.DONE &&
        tableDataConfig.map(
          (config, tableIndex) =>
            config?.rows &&
            config.rows.length > 0 && (
              <TableComponent
                apiData={tables}
                tableHeadConfig={tableHeadConfig}
                tableDataConfig={config}
                tableId={`table-${tableIndex}`}
                state={state}
                store={store}
                key={`table-${tableIndex}`}
              />
            ),
        )}
    </>
  )
}
