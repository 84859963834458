import {
  PageHeader as WcPageHeader,
  PageHeaderProps as WcPageHeaderProps,
  theme,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { NotificationsBanner } from './notifications/notifications-banner'

interface PageHeaderProps extends WcPageHeaderProps {}

export const StyledDiv = styled.div`
  background-color: ${theme.COLORS.greys.ultraLight};
`

export function PageHeader(props: PageHeaderProps) {
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <WcPageHeader {...props} />
      </Box>
      <NotificationsBanner />
    </>
  )
}
