import { PageWrapper, theme } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useNavAndFooterConstants } from '../../../hooks/use-nav-and-footer-constants'
import { FooterLinks } from './footer-links'

export function Footer({ withOutLeftNav }: { withOutLeftNav?: boolean }) {
  const { footerHeight, leftNavigationWidth } = useNavAndFooterConstants()

  return (
    <Box
      component="footer"
      sx={{
        position: 'absolute',
        width: '100%',
        bottom: 0,
        height: footerHeight,
      }}
    >
      <Box
        sx={{
          py: 4,
          background: theme.COLORS.secondary.darkBlue.primary,
          ml: withOutLeftNav ? 0 : leftNavigationWidth,
        }}
      >
        <PageWrapper>
          <Box
            sx={{
              display: { xs: 'block', lg: 'flex' },
              justifyContent: 'end',
            }}
          >
            <FooterLinks />
          </Box>
        </PageWrapper>
      </Box>
    </Box>
  )
}
