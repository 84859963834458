import { theme } from '@maersktankersdigital/web-components'
import styled, { css, keyframes } from 'styled-components/macro'

const fillHeartAnimation = keyframes`
  from {
    fill: transparent;
    stroke: #6c7f8d;
  }
  to {
    fill: ${theme.COLORS.red.mid.primary};
    stroke: ${theme.COLORS.red.mid.primary};
  }
`

const unfillHeartAnimation = keyframes`
  from {
    fill: ${theme.COLORS.red.mid.primary};
    stroke: ${theme.COLORS.red.mid.primary};
  }
  to {
    fill: transparent;
    stroke: #6c7f8d;
  }
`

const StyledSvgHeart = styled.svg<{
  animateFill?: boolean
  animateUnfill?: boolean
  filled: boolean
}>`
  width: 16px;
  height: 16px;
  cursor: pointer;

  && path {
    fill: ${({ filled }) =>
      filled ? theme.COLORS.red.mid.primary : 'transparent'};
    stroke: ${({ filled }) =>
      filled ? theme.COLORS.red.mid.primary : '#6c7f8d'};
    stroke-width: 1;
    animation: ${({ animateFill, animateUnfill }) =>
      animateFill && !animateUnfill
        ? css`
            ${fillHeartAnimation} 1s forwards
          `
        : animateUnfill && !animateFill
          ? css`
              ${unfillHeartAnimation} 0.8s forwards
            `
          : 'none'};
  }
`

interface Props {
  animateFill?: boolean
  animateUnfill?: boolean
  filled: boolean
  onClick?: () => void
}

function AnimatedHeart({ filled, animateFill, onClick, animateUnfill }: Props) {
  return (
    <StyledSvgHeart
      viewBox="0 0 24 24"
      onClick={onClick}
      filled={filled}
      animateFill={animateFill}
      animateUnfill={animateUnfill}
    >
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
    </StyledSvgHeart>
  )
}

export default AnimatedHeart
